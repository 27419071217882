/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField, updateField } from "vuex-map-fields"
import { debounce } from "lodash"

import SearchUtils from "@/search/utils"
import WebLinksApi from "@/data/weblink/api"

const getDefaultSelectedState = () => {
    return {
        url: null,
        count: null,
        title: null,
        id: null,
        description: null,
        loading: false,
    }
}

const state = {
    selected: {
        ...getDefaultSelectedState(),
    },
    dialogs: {
        showCreateEdit: false,
        showRemove: false,
    },
    table: {
        rows: {
            items: [],
            total: null,
        },
        options: {
            q: "",
            page: 1,
            itemsPerPage: 10,
            sortBy: ["count"],
            descending: [true],
            filters: {
                project: [],
            },
        },
        loading: false,
    },
}

const getters = {
    getField,
}

const actions = {
    getAll: debounce(({ commit, state }) => {
        commit("SET_TABLE_LOADING", "primary")
        let params = SearchUtils.createParametersFromTableOptions({ ...state.table.options }, "WebLink")
        return WebLinksApi.getAll(params)
            .then((response) => {
                commit("SET_TABLE_LOADING", false)
                commit("SET_TABLE_ROWS", response.data)
            })
            .catch(() => {
                commit("SET_TABLE_LOADING", false)
            })
    }, 500),
    createEditShow({ commit }, weblink) {
        commit("SET_DIALOG_CREATE_EDIT", true)
        if (weblink) {
            commit("SET_SELECTED", weblink)
        }
    },
    copyToClipboardSuccess({ commit }) {
        commit(
            "notification_backend/addBeNotification",
            { text: "Copied to clipboard.", type: "success" },
            { root: true }
        )
    },
    removeShow({ commit }, weblink) {
        commit("SET_DIALOG_DELETE", true)
        commit("SET_SELECTED", weblink)
    },
    closeCreateEdit({ commit }) {
        commit("SET_DIALOG_CREATE_EDIT", false)
        commit("RESET_SELECTED")
    },
    closeRemove({ commit }) {
        commit("SET_DIALOG_DELETE", false)
        commit("RESET_SELECTED")
    },
    save({ commit, dispatch }) {
        commit("SET_SELECTED_LOADING", true)
        if (!state.selected.id) {
            return WebLinksApi.create(state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Link created successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        } else {
            return WebLinksApi.update(state.selected.id, state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Link updated successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        }
    },
    remove({ commit, dispatch }) {
        return WebLinksApi.delete(state.selected.id).then(function () {
            dispatch("closeRemove")
            dispatch("getAll")
            commit(
                "notification_backend/addBeNotification",
                { text: "Link deleted successfully.", type: "success" },
                { root: true }
            )
        })
    },
}

const mutations = {
    updateField,
    SET_SELECTED(state, value) {
        state.selected = Object.assign(state.selected, value)
    },
    SET_SELECTED_LOADING(state, value) {
        state.selected.loading = value
    },
    SET_TABLE_LOADING(state, value) {
        state.table.loading = value
    },
    SET_TABLE_ROWS(state, value) {
        state.table.rows = value
    },
    SET_DIALOG_CREATE_EDIT(state, value) {
        state.dialogs.showCreateEdit = value
    },
    SET_DIALOG_DELETE(state, value) {
        state.dialogs.showRemove = value
    },
    RESET_SELECTED(state) {
        // do not reset project
        let project = state.selected.project
        state.selected = { ...getDefaultSelectedState() }
        state.selected.project = project
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
