import { getField, updateField } from "vuex-map-fields"
import { debounce } from "lodash"

import SearchUtils from "@/search/utils"
import CostModelApi from "@/cost_model/api"

const getDefaultSelectedState = () => {
    return {
        id: null,
        name: null,
        enabled: null,
        description: null,
        created_at: null,
        updated_at: null,
        project: null,
        loading: false,
        activities: [],
    }
}

const state = {
    selected: {
        ...getDefaultSelectedState(),
    },
    dialogs: {
        showCreateEdit: false,
        showRemove: false,
        showActivity: false,
    },
    table: {
        rows: {
            items: [],
            total: null,
        },
        options: {
            q: "",
            page: 1,
            itemsPerPage: 25,
            sortBy: ["CostModel.created_at"],
            descending: [true],
            filters: {
                project: [],
            },
        },
        loading: false,
    },
}

const getters = {
    getField,
}

const actions = {
    getAll: debounce(({ commit, state }) => {
        commit("SET_TABLE_LOADING", "primary")
        let params = SearchUtils.createParametersFromTableOptions(
            { ...state.table.options },
            "CostModel"
        )
        return CostModelApi.getAll(params)
            .then((response) => {
                commit("SET_TABLE_LOADING", false)
                commit("SET_TABLE_ROWS", response.data)
            })
            .catch(() => {
                commit("SET_TABLE_LOADING", false)
            })
    }, 500),
    createEditShow({ commit }, incidentCostModel) {
        commit("SET_DIALOG_EDIT", true)
        if (incidentCostModel) {
            commit("SET_SELECTED", incidentCostModel)
        }
    },
    closeCreateEdit({ commit }) {
        commit("SET_DIALOG_EDIT", false)
        commit("RESET_SELECTED")
    },
    createActivityShow({ commit }) {
        commit("SET_DIALOG_ACTIVITY", true)
    },
    closeActivity({ commit }) {
        commit("SET_DIALOG_ACTIVITY", false)
    },
    removeShow({ commit }, incidentCostModel) {
        commit("SET_DIALOG_DELETE", true)
        commit("SET_SELECTED", incidentCostModel)
    },
    closeRemove({ commit }) {
        commit("SET_DIALOG_DELETE", false)
        commit("RESET_SELECTED")
    },
    save({ commit, state, dispatch }) {
        commit("SET_SELECTED_LOADING", true)
        if (!state.selected.id) {
            return CostModelApi.create(state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Cost model created successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        } else {
            return CostModelApi.update(state.selected.id, state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Cost model updated successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        }
    },
    remove({ commit, state, dispatch }) {
        return CostModelApi.delete(state.selected.id).then(function () {
            dispatch("closeRemove")
            dispatch("getAll")
            commit(
                "notification_backend/addBeNotification",
                { text: "Cost model deleted successfully.", type: "success" },
                { root: true }
            )
        })
    },
}

const mutations = {
    updateField,
    SET_SELECTED(state, value) {
        state.selected = Object.assign(state.selected, value)
    },
    SET_SELECTED_LOADING(state, value) {
        state.selected.loading = value
    },
    SET_TABLE_LOADING(state, value) {
        state.table.loading = value
    },
    SET_TABLE_ROWS(state, value) {
        state.table.rows = value
    },
    SET_DIALOG_EDIT(state, value) {
        state.dialogs.showCreateEdit = value
    },
    SET_DIALOG_DELETE(state, value) {
        state.dialogs.showRemove = value
    },
    SET_DIALOG_ACTIVITY(state, value) {
        state.dialogs.showActivity = value
    },
    RESET_SELECTED(state) {
        // do not reset project
        let project = state.selected.project
        state.selected = { ...getDefaultSelectedState() }
        state.selected.project = project
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
