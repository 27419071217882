import API from "@/api"

const resource = "/entity"

export default {
  getAll(options) {
    return API.get(`${resource}`, { params: { ...options } })
  },

  getAllByType(entityType, options) {
    return API.get(`${resource}/type/${entityType}`, { params: { ...options } })
  },

  getAllByClass(entityClass, options) {
    return API.get(`${resource}/class/${entityClass}`, {
      params
        : { ...options }
    })
  },

  get(entityId) {
    return API.get(`${resource}/${entityId}`)
  },

  create(payload) {
    return API.post(`${resource}`, payload)
  },

  update(entityId, payload) {
    return API.put(`${resource}/${entityId}`, payload)
  },

  delete(entityId) {
    return API.delete(`${resource}/${entityId}`)
  },

  subscribe(entityId) {
    return API.post(`${resource}/${entityId}/subscribe`)
  },

  async getCases(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/cases/${daysBack}`)
  },

  async getIncidents(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/incidents/${daysBack}`)
  },

  async getSignalInstances(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/signal_instances/${daysBack}`)
  },

  async getCommits(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/commits/${daysBack}`)
  },

  async getTickets(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/tickets/${daysBack}`)
  },

  async getIndividuals(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/individuals/${daysBack}`)
  },

  async getConversations(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/conversations/${daysBack}`)
  },

  async getDocuments(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/documents/${daysBack}`)
  },

  async getTasks(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/tasks/${daysBack}`)
  },

  async getConferences(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/conferences/${daysBack}`)
  },
  async getWeblinks(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/weblinks/${daysBack}`)
  },
  async getEmails(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/emails/${daysBack}`)
  },
  async getECCSProjects(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/eccs_projects/${daysBack}`)
  },
  async getTextSubmissions(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/text_submissions/${daysBack}`)
  },
  async getFileSubmissions(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/file_submissions/${daysBack}`)
  },
  async getYouTubeTranscripts(entityId, daysBack) {
    return await API.get(`${resource}/${entityId}/youtube_transcripts/${daysBack}`)
  },
}
