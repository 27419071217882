/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/

import API from "@/api"

const resource = "/commits" // Adjust the resource path to match your backend API endpoint for commits

export default {
    getAll(options) {
        // Fetches all commits, possibly with filters, sorting, and pagination
        return API.get(`${resource}`, { params: { ...options } })
    },

    get(commitId) {
        // Fetches a single commit by its ID
        return API.get(`${resource}/${commitId}`)
    },

    create(payload) {
        // Creates a new commit with the provided payload
        return API.post(`${resource}`, payload)
    },

    update(commitId, payload) {
        // Updates an existing commit identified by commitId with the new payload
        return API.put(`${resource}/${commitId}`, payload)
    },

    delete(commitId) {
        // Deletes a commit identified by commitId
        return API.delete(`${resource}/${commitId}`)
    },
}
