/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import API from "@/api"

const resource = "cases"

export default {
  getAll(options) {
    return API.get(`/${resource}`, {
      params: { ...options },
    })
  },

  get(caseId) {
    return API.get(`/${resource}/${caseId}`)
  },

  getProbableCause(caseId) {
    return API.get(`/${resource}/${caseId}/probable_cause`)
  },

  create(payload) {
    return API.post(`/${resource}`, payload)
  },

  update(caseId, payload) {
    return API.put(`/${resource}/${caseId}`, payload)
  },

  escalate(caseId, payload) {
    return API.put(`/${resource}/${caseId}/escalate`, payload)
  },

  bulkUpdate(cases, payload) {
    return Promise.all(
      cases.map((case_obj) => {
        return this.update(case_obj.id, { ...case_obj, ...payload })
      })
    )
  },

  bulkDelete(cases) {
    return Promise.all(
      cases.map((case_obj) => {
        return this.delete(case_obj.id)
      })
    )
  },

  delete(caseId) {
    return API.delete(`/${resource}/${caseId}`)
  },
  addComment(caseId, payload) {
    return API.post(`/${resource}/${caseId}/comments/add`, payload)
  },
}
