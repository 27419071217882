import API from "@/api"

const resource = "projects"

export default {
  getAll(options) {
    const token = localStorage.getItem("token");
    const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

    if (token) {
      return API.get(`/${resource}`, {
        params: { ...options },
      });
    } else if (unstruct_anonymous_key) {
      return API.get(`/${resource}/anonymous/${unstruct_anonymous_key}`, {
        params: { ...options },
      });
    } else {
      return Promise.reject(new Error("No authentication or temporary session found"));
    }
  },

  get(projectId) {
    return API.get(`/${resource}/${projectId}`)
  },

  getUserProjects(options) {
    const token = localStorage.getItem("token");
    const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

    if (token) {
      return API.get(`/${resource}/user`, {
        params: { ...options },
      });
    } else if (unstruct_anonymous_key) {
      return API.get(`/${resource}/anonymous/user/${unstruct_anonymous_key}`, {
        params: { ...options },
      });
    } else {
      return Promise.reject(new Error("No authentication or temporary session found"));
    }
  },

  create(payload) {
    const token = localStorage.getItem("token");
    const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

    if (token) {
      return API.post(`/${resource}`, payload);
    } else if (unstruct_anonymous_key) {
      return API.post(`/${resource}/anonymous/${unstruct_anonymous_key}`, payload);
    } else {
      return Promise.reject(new Error("No authentication or temporary session found"));
    }
  },

  update(projectId, payload) {
    return API.put(`/${resource}/${projectId}`, payload)
  },

  delete(projectId) {
    return API.delete(`/${resource}/${projectId}`)
  },
}
