/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import API from "@/api"

const resource = "/data/commands"

export default {
    getAll(options) {
        return API.get(`${resource}`, { params: { ...options } })
    },

    get(commandId) {
        return API.get(`${resource}/${commandId}`)
    },

    create(payload) {
        return API.post(`${resource}`, payload)
    },

    update(commandId, payload) {
        return API.put(`${resource}/${commandId}`, payload)
    },

    delete(commandId) {
        return API.delete(`${resource}/${commandId}`)
    },
}
