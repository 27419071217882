import store from "@/store"
import UserApi from "./api"

function load() {
  const currentPath = window.location.pathname.split("/")
  var currentOrganization = currentPath.length > 1 ? currentPath[1] : "default"

  // Get the user's current organization
  currentOrganization = localStorage.getItem("organization")

  // Update Vuex state
  store.commit("auth/SET_CURRENT_ORGANIZATION", currentOrganization)

  return UserApi.getUserInfo().then(function (response) {
    return store.commit("auth/SET_USER_PROJECTS", response.data.projects)
  })
}

export default {
  load,
}
