/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField } from "vuex-map-fields"

import SearchUtils from "@/search/utils"
import OKRsApi from "@/okrs/api"

const getDefaultSelectedState = () => {
    return {
        id: null,
        description: null,
        title: null,
        key_results: [],
        progress: null,
        project: null,
        loading: false,
    }
}

const state = {
    selected: {
        ...getDefaultSelectedState(),
    },
    dialogs: {
        showCreateEdit: false,
        showRemove: false,
    },
    table: {
        rows: {
            items: [],
            total: null,
        },
        options: {
            q: "",
            page: 1,
            itemsPerPage: 10,
            sortBy: ["name"],
            descending: [false],
            filters: {
                project: [],
            },
        },
        loading: false,
    },
}

const getters = {
    getField,
}

const actions = {
    getAll: async ({ commit, state }) => {
        commit("SET_TABLE_LOADING", "primary")
        let params = SearchUtils.createParametersFromTableOptions({ ...state.table.options }, "okrs")
        commit("SET_TABLE_LOADING", false)
        try {
            const response = await OKRsApi.getAll(params)
            commit("SET_TABLE_ROWS", response.data)
            return response
        } catch (error) {
            console.error('Error fetching OKRs:', error)
        }
    },
}


const mutations = {
    SET_SELECTED(state, value) {
        state.selected = Object.assign(state.selected, value)
    },
    SET_SELECTED_LOADING(state, value) {
        state.selected.loading = value
    },
    SET_TABLE_LOADING(state, value) {
        state.table.loading = value
    },
    SET_TABLE_ROWS(state, value) {
        state.table.rows = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
