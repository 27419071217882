/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import Vue from "vue"
import Router from "vue-router"
import { publicRoute, protectedRoute } from "./config"

import store from "@/store"
import pkceAuthProvider from "@/auth/pkceAuthProvider"
import basicAuthProvider from "@/auth/basicAuthProvider"
import customAuthProvider from "@/auth/customAuthProvider"
import userSettings from "@/auth/userSettings"

const routes = protectedRoute.concat(publicRoute)

// Filter out the 'settings' route
const filteredRoutes = [
  ...publicRoute,
  ...protectedRoute.filter(route => route.name !== "settings"),
]

Vue.use(Router)

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: routes,
})

const authProviderSlug =
  process.env.VUE_APP_UNSTRUCT_AUTHENTICATION_PROVIDER_SLUG || "unstruct-auth-provider-basic"
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

// router guards
router.beforeEach((to, from, next) => {
  const storedOrg = localStorage.getItem("organization") || "default";
  const pathParts = to.path.split('/').filter(part => part !== '');

  // Check if we're at the root URL
  if (pathParts.length === 0) {
    // Redirect to the home route for the stored organization
    next({
      name: 'chat',
      params: { organization: storedOrg },
      query: to.query,
      hash: to.hash,
      replace: true
    });
    return;
  }

  const urlOrg = pathParts[0];

  if (urlOrg !== storedOrg) {
    // If the URL org doesn't match the stored org, redirect
    pathParts[0] = storedOrg;
    next({
      path: '/' + pathParts.join('/'),
      query: to.query,
      hash: to.hash,
      replace: true
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  // set loading to true only if from and to are different
  const isNavigationDifferent =
    from.name !== to.name ||
    JSON.stringify(from.params) !== JSON.stringify(to.params) ||
    JSON.stringify(from.query) !== JSON.stringify(to.query);

  if (isNavigationDifferent) {
    store.dispatch("app/setLoading", true)
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.currentUser.loggedIn) {
      if (authProviderSlug === "unstruct-auth-provider-basic") {
        basicAuthProvider.login(to, from, next)
      } else if (authProviderSlug === "unstruct-auth-provider-pkce") {
        pkceAuthProvider.login(to, from, next)
      } else {
        // defaults to none, allows custom providers
        customAuthProvider
          .login(to, from, next)
          .then(function () {
            return userSettings.load()
          })
          .then(next())
      }
    } else {
      if (to.name === 'Root') {
        const targetOrg = localStorage.getItem("organization") || 'default'
        next({ name: 'chat', params: { organization: targetOrg } })
      } else {
        userSettings.load().then(next())
      }
    }
  } else {
    next()
  }
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  // We only want to automatically copy params when we are navigating
  // "project" subMenu's
  if (to.meta.subMenu === "project") {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      return next({ name: to.name, query: from.query });
    }
  }
  return next();
})

router.afterEach(function () {
  store.dispatch("app/setLoading", false)
})

export default router
