/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField, updateField } from "vuex-map-fields";
import { debounce } from "lodash";

import SearchUtils from "@/search/utils";
import CommitApi from "@/commit/api";

const getDefaultSelectedState = () => {
    return {
        id: null,
        loading: false,
        commit_id: null,
        commit_message: null,
        commit_author: null,
        commit_date: null,
        repository_url: null,
        repository_name: null,
        repository_branch: null,
        source: "GitHub",
        sync_completed: false,
        sync_completed_at: null,
    };
};

const state = {
    selected: { ...getDefaultSelectedState() },
    dialogs: {
        showCreateEdit: false,
        showRemove: false,
    },
    table: {
        rows: {
            items: [],
            total: null,
        },
        options: {
            q: "",
            page: 1,
            itemsPerPage: 25,
            sortBy: ["commit_date"],
            descending: [true],
            filters: {
                commit_id: null,
                commit_message: null,
                commit_author: null,
                commit_date: null,
                repository_url: null,
                repository_name: null,
                repository_branch: null,
                source: null,
                sync_completed: null,
            },
        },
        loading: false,
    },
};

const getters = {
    getField,
};

const actions = {
    getAll: debounce(({ commit, state }) => {
        commit("SET_TABLE_LOADING", true);
        let params = SearchUtils.createParametersFromTableOptions({ ...state.table.options }, "Commit");
        return CommitApi.getAll(params)
            .then((response) => {
                commit("SET_TABLE_LOADING", false);
                commit("SET_TABLE_ROWS", response.data);
            })
            .catch(() => {
                commit("SET_TABLE_LOADING", false);
            });
    }, 500),
    createEditShow({ commit }, commitData) {
        commit("SET_DIALOG_CREATE_EDIT", true);
        if (commitData) {
            commit("SET_SELECTED", commitData);
        }
    },
    copyToClipboardSuccess({ commit }) {
        commit(
            "notification_backend/addBeNotification",
            { text: "Copied to clipboard.", type: "success" },
            { root: true }
        )
    },
    removeShow({ commit }, commitData) {
        commit("SET_DIALOG_DELETE", true);
        commit("SET_SELECTED", commitData);
    },
    closeCreateEdit({ commit }) {
        commit("SET_DIALOG_CREATE_EDIT", false)
        commit("RESET_SELECTED")
    },
    closeRemove({ commit }) {
        commit("SET_DIALOG_DELETE", false)
        commit("RESET_SELECTED")
    },
    getDetails({ commit, state }, payload) {
        commit("SET_SELECTED_LOADING", true)
        if ("id" in payload) {
            return CommitApi.get(state.selected.id).then((response) => {
                commit("SET_SELECTED", response.data)
                commit("SET_SELECTED_LOADING", false)
            })
        } else if ("name" in payload) {
            // this is kinda dirty
            return CommitApi.getAll({
                filter: JSON.stringify([
                    {
                        and: [
                            {
                                model: "Commit",
                                field: "name",
                                op: "==",
                                value: payload.name,
                            },
                        ],
                    },
                ]),
            }).then((response) => {
                if (response.data.items.length) {
                    commit("SET_SELECTED", response.data.items[0])
                } else {
                    commit(
                        "notification_backend/addBeNotification",
                        {
                            text: `Commit '${payload.name}' could not be found.`,
                            type: "error",
                        },
                        { root: true }
                    )
                    commit("SET_DIALOG_SHOW_EDIT_SHEET", false)
                }
                commit("SET_SELECTED_LOADING", false)
            })
        }
    },
    save({ commit, dispatch }) {
        commit("SET_SELECTED_LOADING", true)
        if (!state.selected.id) {
            return CommitApi.create(state.selected)
                .then(function (resp) {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Commit created successfully.", type: "success" },
                        { root: true }
                    )
                    return resp.data
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        } else {
            return CommitApi.update(state.selected.id, state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Commit updated successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        }
    },
    remove({ commit, dispatch }) {
        return CommitApi.delete(state.selected.id).then(function () {
            dispatch("closeRemove")
            dispatch("getAll")
            commit(
                "notification_backend/addBeNotification",
                { text: "Commit deleted successfully.", type: "success" },
                { root: true }
            )
        })
    },
};


const mutations = {
    updateField,
    SET_SELECTED(state, value) {
        state.selected = Object.assign(state.selected, value)
    },
    SET_SELECTED_LOADING(state, value) {
        state.selected.loading = value
    },
    SET_TABLE_LOADING(state, value) {
        state.table.loading = value
    },
    SET_TABLE_ROWS(state, value) {
        state.table.rows = value
    },
    SET_DIALOG_CREATE_EDIT(state, value) {
        state.dialogs.showCreateEdit = value
    },
    SET_DIALOG_DELETE(state, value) {
        state.dialogs.showRemove = value
    },
    RESET_SELECTED(state) {
        state.selected = { ...getDefaultSelectedState() }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
