<template>
  <v-dialog v-model="showCreateEdit" persistent max-width="800px">
    <ValidationObserver v-slot="{ invalid }" slim>
      <v-card>
        <v-card-title class="headline">
          {{ id ? 'Edit Universe' : 'Create a new universe' }}
        </v-card-title>
        <v-card-text>
          <p class="text--secondary mb-4">
            Universes represent the top-level in your hierarchy. You'll be able to bundle a
            collection of galaxies within an organization.
          </p>
          <ValidationProvider name="Name" rules="required" v-slot="{ errors, valid }">
            <v-text-field
              v-model="name"
              label="Name"
              hint="A name for your organization. Note: it can't be modified once the organization has been created."
              :error-messages="errors"
              :success="valid"
              :disabled="!!id"
              outlined
              dense
              required
            />
          </ValidationProvider>
          <ValidationProvider name="Description" rules="required" v-slot="{ errors, valid }">
            <v-textarea
              v-model="description"
              label="Description"
              hint="A short description for your organization."
              :error-messages="errors"
              :success="valid"
              outlined
              dense
              auto-grow
              required
            />
          </ValidationProvider>

          <v-alert
            v-if="!user.is_pro_team"
            type="info"
            outlined
            dense
            class="mt-4 mb-4"
          >
            The following features are available only with a Custom Plan.
          </v-alert>

          <v-checkbox
            v-model="storycurve_logging_enabled"
            label="Storycurve Logging Enabled"
            :disabled="!user.is_pro_team"
          >
            <template v-slot:append>
              <v-tooltip bottom max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small>mdi-help-circle-outline</v-icon>
                </template>
                <span>StoryCurve is an advanced entity attribute logging and analysis system. It captures comprehensive data about entities, enabling deep, multi-dimensional analysis across various contexts.</span>
              </v-tooltip>
            </template>
          </v-checkbox>

          <v-subheader class="pl-0 mt-4">
            Banner Settings
            <v-tooltip bottom max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="ml-2">mdi-help-circle-outline</v-icon>
              </template>
              <span>When enabled, this banner will be presented to users throughout the application when using this organization.</span>
            </v-tooltip>
          </v-subheader>

          <ValidationProvider name="text" v-slot="{ errors, valid }">
            <v-textarea
              v-model="banner_text"
              label="Text"
              hint="Any information you would like to include in an organizational banner."
              :error-messages="errors"
              :success="valid"
              outlined
              dense
              auto-grow
              :disabled="!user.is_pro_team"
            />
          </ValidationProvider>
          <color-picker-input
            label="Color"
            v-model="banner_color"
            :disabled="!user.is_pro_team"
          />
          <v-checkbox
            v-model="banner_enabled"
            label="Enabled"
            :disabled="!user.is_pro_team"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeCreateEditDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="loading"
            :disabled="invalid"
          >
            {{ id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import { mapState } from "vuex"
import { required } from "vee-validate/dist/rules"

import ColorPickerInput from "@/components/ColorPickerInput.vue"

extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  name: "OrganizationCreateEditDialog",

  components: {
    ColorPickerInput,
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapState('websocket', ['user']),
    ...mapFields("organization", [
      "selected.id",
      "selected.name",
      "selected.description",
      "selected.banner_text",
      "selected.banner_color",
      "selected.banner_enabled",
      "selected.storycurve_logging_enabled",
      "selected.custom_plan",
      "selected.loading",
      "dialogs.showCreateEdit",
    ]),
  },

  methods: {
    ...mapActions("organization", ["save", "closeCreateEditDialog"]),
  },
}
</script>

<style scoped>
.v-tooltip {
  display: inline-block;
}
.headline {
  font-size: 2rem !important;
}
</style>
