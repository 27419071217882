/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField, updateField } from "vuex-map-fields"
import { debounce } from "lodash"

import SearchUtils from "@/search/utils"
import PluginApi from "@/plugin/api"

const getDefaultSelectedState = () => {
  return {
    id: null,
    enabled: null,
    configuration: [],
    configuration_schema: {},
    project: null,
    plugin_instance: null,
    plugin: null,
    loading: false,
  }
}

const state = {
  selected: {
    ...getDefaultSelectedState(),
  },
  dialogs: {
    showCreateEdit: false,
    showRemove: false,
  },
  table: {
    rows: {
      items: [],
      total: null,
    },
    options: {
      q: "",
      page: 1,
      itemsPerPage: 75,
      sortBy: ["Plugin.slug"],
      descending: [true],
      filters: {
        project: [],
      },
    },
    loading: false,
  },
}

const getters = {
  getField,
  effectiveConfiguration: (state) => {
    if (state.selected.configuration) {
      return state.selected.configuration;
    } else if (state.selected.configuration_schema) {
      return generateInitialConfiguration(state.selected.configuration_schema);
    } else {
      return null;
    }
  },
}

const actions = {
  setInitialConfiguration({ commit, state }, schema) {
    if (!state.selected.configuration) {
      const configuration = generateInitialConfiguration(schema);
      commit('setConfiguration', configuration);
    }
  },
  getAll: debounce(({ commit, state }) => {
    commit("SET_TABLE_LOADING", "primary")
    let params = SearchUtils.createParametersFromTableOptions({ ...state.table.options }, "Plugin")
    return PluginApi.getAll(params)
      .then((response) => {
        commit("SET_TABLE_LOADING", false)
        commit("SET_TABLE_ROWS", response.data)
      })
      .catch(() => {
        commit("SET_TABLE_LOADING", false)
      })
  }, 500),
  getAllInstances: debounce(({ commit, state }) => {
    commit("SET_TABLE_LOADING", "primary")
    let params = SearchUtils.createParametersFromTableOptions({ ...state.table.options }, "Plugin")
    return PluginApi.getAllInstances(params)
      .then((response) => {
        commit("SET_TABLE_LOADING", false)
        commit("SET_TABLE_ROWS", response.data)
      })
      .catch(() => {
        commit("SET_TABLE_LOADING", false)
      })
  }, 500),
  createEditShow({ commit }, plugin) {
    commit("SET_DIALOG_EDIT", true)
    if (plugin) {
      PluginApi.getInstance(plugin.id).then((response) => {
        commit("SET_SELECTED", response.data)
      })
    }
  },
  closeCreateEdit({ commit }) {
    commit("SET_DIALOG_EDIT", false)
    commit("RESET_SELECTED")
  },
  removeShow({ commit }, plugin) {
    commit("SET_DIALOG_DELETE", true)
    commit("SET_SELECTED", plugin)
  },
  closeRemove({ commit }) {
    commit("SET_DIALOG_DELETE", false)
    commit("RESET_SELECTED")
  },
  save({ commit, dispatch }) {
    commit("SET_SELECTED_LOADING", true)
    if (!state.selected.id) {
      return PluginApi.createInstance(state.selected)
        .then(() => {
          commit("SET_SELECTED_LOADING", false)
          dispatch("closeCreateEdit")
          dispatch("getAllInstances")
          commit(
            "notification_backend/addBeNotification",
            { text: "Plugin instance created successfully.", type: "success" },
            { root: true }
          )
        })
        .catch(() => {
          commit("SET_SELECTED_LOADING", false)
        })
    } else {
      return PluginApi.updateInstance(state.selected.id, state.selected)
        .then(() => {
          commit("SET_SELECTED_LOADING", false)
          dispatch("closeCreateEdit")
          dispatch("getAllInstances")
          commit(
            "notification_backend/addBeNotification",
            { text: "Plugin instance updated successfully.", type: "success" },
            { root: true }
          )
        })
        .catch(() => {
          commit("SET_SELECTED_LOADING", false)
        })
    }
  },
  remove({ commit, dispatch }) {
    return PluginApi.deleteInstance(state.selected.id).then(function () {
      dispatch("closeRemove")
      dispatch("getAllInstances")
      commit(
        "notification_backend/addBeNotification",
        { text: "Plugin instance deleted successfully.", type: "success" },
        { root: true }
      )
    })
  },
}

const generateInitialConfiguration = (schema) => {
  console.log("Generating initial configuration from schema", schema)
  const config = {}
  Object.keys(schema.properties).forEach((key) => {
    console.log("Generating initial configuration for key", key)
    const property = schema.properties[key]
    if ("default" in property) {
      config[key] = property.default
    } else {
      switch (property.type) {
        case "string":
          config[key] = ""
          break
        case "number":
          config[key] = 0
          break
        case "boolean":
          config[key] = false
          break
        case "array":
          config[key] = []
          break
        case "object":
          config[key] = {}
          break
        default:
          config[key] = null
      }
    }
  })
  return config
};

const mutations = {
  setConfiguration(state, payload) {
    state.selected.configuration = payload;
  },
  updateField,
  SET_SELECTED(state, value) {
    state.selected = Object.assign(state.selected, value)
  },
  SET_SELECTED_LOADING(state, value) {
    state.selected.loading = value
  },
  SET_TABLE_LOADING(state, value) {
    state.table.loading = value
  },
  SET_TABLE_ROWS(state, value) {
    state.table.rows = value
  },
  SET_DIALOG_EDIT(state, value) {
    state.dialogs.showCreateEdit = value
  },
  SET_DIALOG_DELETE(state, value) {
    state.dialogs.showRemove = value
  },
  RESET_SELECTED(state) {
    // do not reset project
    let project = state.selected.project
    state.selected = { ...getDefaultSelectedState() }
    state.selected.project = project
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
