import ProjectApi from "@/project/api"

import { getField, updateField } from "vuex-map-fields"
import { debounce } from "lodash"

const getDefaultSelectedState = () => {
  return {
    name: null,
    id: null,
    description: null,
    organization: {
      name: localStorage.getItem('organization'),
      slug: localStorage.getItem('organization')
    },
    color: null,
    custom_ttp_patterns: [],
    loading: false,
    annual_employee_cost: 50000,
    business_year_hours: 2080,
    owner_email: null,
    owner_conversation: null,
  }
}

const state = {
  selected: {
    ...getDefaultSelectedState(),
  },
  dialogs: {
    showCreateEdit: false,
    showRemove: false,
  },
  table: {
    rows: {
      items: [],
      total: null,
    },
    options: {
      q: "",
      page: 1,
      itemsPerPage: 10,
      items_per_page: 9999, // Used for fetching all projects
      sortBy: ["name"],
      descending: [false],
    },
    loading: false,
  },
  galaxies: [],
}

const getters = {
  getField,
}

const actions = {
  getAll: debounce(({ commit, state }) => {
    commit("SET_TABLE_LOADING", "primary")
    return ProjectApi.getAll(state.table.options)
      .then((response) => {
        commit("SET_TABLE_LOADING", false)
        commit("SET_TABLE_ROWS", response.data)
      })
      .catch(() => {
        commit("SET_TABLE_LOADING", false)
      })
  }, 500),
  createEditShow({ commit }, project) {
    commit("SET_DIALOG_CREATE_EDIT", true)
    if (project) {
      commit("SET_SELECTED", project)
    }
  },
  removeShow({ commit }, project) {
    commit("SET_DIALOG_DELETE", true)
    commit("SET_SELECTED", project)
  },
  closeCreateEdit({ commit }) {
    commit("SET_DIALOG_CREATE_EDIT", false)
    commit("RESET_SELECTED")
  },
  closeRemove({ commit }) {
    commit("SET_DIALOG_DELETE", false)
    commit("RESET_SELECTED")
  },
  save({ commit, dispatch }) {
    commit("SET_SELECTED_LOADING", true);

    const orgSlug = localStorage.getItem('organization');
    const payload = {
      ...state.selected,
      organization: {
        name: orgSlug,
        slug: orgSlug
      }
    };

    // Validate organization exists
    if (!orgSlug) {
      commit("SET_SELECTED_LOADING", false);
      return {
        success: false,
        error: 'Organization not found in localStorage'
      };
    }

    return ProjectApi.create(state.selected)
      .then((response) => {
        const savedProject = response.data;
        commit("SET_SELECTED", savedProject);
        commit("SET_SELECTED_LOADING", false);
        localStorage.setItem("project", savedProject.id);
        commit("auth/SET_CURRENT_PROJECT", savedProject.id, { root: true });
        dispatch("getAll");
        return { success: true, data: savedProject };
      })
      .catch((error) => {
        commit("SET_SELECTED_LOADING", false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return { success: false, status: error.response.status, data: error.response.data };
        } else if (error.request) {
          // The request was made but no response was received
          return { success: false, error: 'No response received' };
        } else {
          // Something happened in setting up the request that triggered an Error
          return { success: false, error: error.message };
        }
      });
  },
  remove({ commit, dispatch }) {
    return ProjectApi.delete(state.selected.id).then(function () {
      dispatch("closeRemove")
      dispatch("getAll")
      commit(
        "notification_backend/addBeNotification",
        { text: "Project deleted successfully.", type: "success" },
        { root: true }
      )
    })
  },
  async fetchGalaxies({ commit, state }) {
    try {
      const token = localStorage.getItem("token");
      const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

      // Return if neither authentication method is present
      if (!token && !unstruct_anonymous_key) {
        return;
      }

      const { data } = await ProjectApi.getUserProjects(state.table.options);
      commit('SET_GALAXIES', data.items);
    } catch (error) {
      console.error('Failed to fetch galaxies:', error);
    }
  },
}

const mutations = {
  updateField,
  SET_SELECTED(state, value) {
    state.selected = {
      ...getDefaultSelectedState(),
      ...value
    };
  },
  SET_SELECTED_LOADING(state, value) {
    state.selected.loading = value
  },
  SET_TABLE_LOADING(state, value) {
    state.table.loading = value
  },
  SET_TABLE_ROWS(state, value) {
    state.table.rows = value
  },
  SET_DIALOG_CREATE_EDIT(state, value) {
    state.dialogs.showCreateEdit = value
  },
  SET_DIALOG_DELETE(state, value) {
    state.dialogs.showRemove = value
  },
  RESET_SELECTED(state) {
    state.selected = Object.assign(state.selected, getDefaultSelectedState())
  },
  SET_GALAXIES(state, galaxies) {
    state.galaxies = galaxies;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
