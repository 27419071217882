/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/

import API from "@/api"

const resource = "/apikeys"

export default {
    getAll(options) {
        return API.get(`${resource}`, { params: { ...options } })
    },

    get(ApiKeyId) {
        return API.get(`${resource}/${ApiKeyId}`)
    },

    create(payload) {
        return API.post(`${resource}`, payload)
    },

    update(ApiKeyId, payload) {
        return API.put(`${resource}/${ApiKeyId}`, payload)
    },

    delete(ApiKeyId) {
        return API.delete(`${resource}/${ApiKeyId}`)
    },
}
