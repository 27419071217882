/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import store from "@/store"

function login(to, from, next) {
  let token = localStorage.getItem("token")

  if (token) {
    store.commit("auth/SET_USER_LOGIN", token)
    next()
  } else {
    // extract the organization slug from the URL if it exists, otherwise use path
    let organizationSlug = to.path.organization
    if (!organizationSlug) {
      organizationSlug = window.location.pathname.split("/")[1] || "default"
    }

    // build the dynamic login path
    const loginPath = `/${organizationSlug}/landing`

    // prevent redirect loop
    if (to.path !== loginPath) {
      next(loginPath)
    } else {
      next()
    }
  }
}

export default {
  login,
}
