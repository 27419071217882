/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { DefaultLayout, DashboardLayout, BasicLayout } from "@/components/layouts"


const withPrefix = (prefix, routes) =>
  routes.map((route) => {
    route.path = prefix + route.path
    return route
  })

const authPages = [
  {
    path: "login",
    name: "BasicLogin",
    component: () => import("@/auth/Login.vue"),
  },
  {
    path: "post-oauth-login",
    name: "PostOauthLogin",
    component: () => import("@/auth/PostOauthLogin.vue"),
  }
]

export const publicRoute = [
  {
    path: "*",
    meta: { title: "Dispatch" },
    component: () => import("@/views/error/NotFound.vue"),
  },
  {
    path: "/:organization/auth/",
    component: BasicLayout,
    meta: { title: "Auth", icon: "view_compact", group: "auth" },
    children: authPages,
  },
  {
    path: "/:organization/landing",
    name: "BasicChat",
    meta: { title: "Public Chat", requiresAuth: false },
    component: () => import("@/websocket/ChatContainer.vue"),
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "Not Found" },
    component: () => import("@/views/error/NotFound.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: { title: "Server Error" },
    component: () => import("@/views/error/Error.vue"),
  },
  {
    path: "/implicit/callback",
    name: "PKCEImplicityCallback",
    meta: { requiresAuth: true },
  },
  {
    path: "/:organization/legal/terms-of-service",
    name: "TermsOfService",
    meta: { title: "Terms of Service", requiresAuth: false },
    component: () => import("@/legal/TermsOfService.vue"),
  },
  {
    path: "/:organization/legal/privacy-policy",
    name: "PrivacyPolicy",
    meta: { title: "Privacy Policy", requiresAuth: false },
    component: () => import("@/legal/PrivacyPolicy.vue"),
  },
  {
    path: "/:organization/help/faq",
    name: "FAQ",
    meta: { title: "Frequently Asked Questions", requiresAuth: false },
    component: () => import("@/help/FAQ.vue"),
  },
  {
    path: "/:organization/blog/:id?",
    name: "Blog",
    meta: { title: "Blog", requiresAuth: false },
    component: () => import("@/blog/UnstructBlog.vue"),
  },
]

// NOTE: The order in which routes are added to the list matters when evaluated. For example, /iaos/report will take precendence over /iaos/:name.
export const protectedRoute = [
  {
    path: "/",
    meta: { requiresAuth: true },
    name: "Root",
    component: () => import("@/components/RootRedirect.vue"),
  },
  ...withPrefix("/:organization/", [
    {
      path: "admin/settings",
      name: "AdminSettings",
      meta: { title: "Admin Settings", requiresAuth: true },
      component: () => import("@/admin_settings/AdminSettings.vue"),
    },
    {
      path: "iaos/status",
      name: "status",
      meta: { title: "Incident Status", requiresAuth: true },
      component: () => import("@/incident/Status.vue"),
    },
    {
      path: "upgrade",
      name: "upgradePlan",
      meta: { title: "Upgrade", requiresAuth: true },
      component: () => import("@/components/UpgradePlan.vue"),
    },
    {
      path: "iaos/report",
      name: "report",
      meta: { title: "Report", requiresAuth: true },
      component: () => import("@/incident/ReportForm.vue"),
    },
    {
      path: "ados",
      name: "entities",
      meta: { title: "Entities", requiresAuth: true },
      component: () => import("@/incident/EntityEntryPage.vue"),
    },
    {
      path: "chat",
      name: "chat",
      meta: { title: "Chat", requiresAuth: true },
      component: () => import("@/websocket/ChatContainer.vue"),
    },
    {
      path: "iaos/other_ados",
      name: "otherentities",
      meta: { title: "Other Entities", requiresAuth: true },
      component: () => import("@/incident/OtherEntityEntryPage.vue"),
    },
    {
      path: "iaos/ado_types",
      name: "entityTypes",
      meta: { title: "Entity Types", requiresAuth: true },
      component: () => import("@/incident/EntityTypeEntryPage.vue"),
    },
    {
      path: "iaos/old_school_search",
      name: "oldSchoolSearch",
      meta: { title: "Old School Search", requiresAuth: true },
      component: () => import("@/incident/OldSchoolSearchEntryPage.vue"),
    },
    {
      path: "cases/status",
      name: "caseStatus",
      meta: { title: "Case Status", requiresAuth: true },
      component: () => import("@/case/Status.vue"),
    },
    {
      path: "cases/report",
      name: "caseReport",
      meta: { title: "Case Report", requiresAuth: true },
      component: () => import("@/case/ReportForm.vue"),
    },
    {
      path: "dashboards",
      component: DashboardLayout,
      name: "dashboards",
      redirect: { name: "IncidentOverview" },
      meta: {
        title: "Dashboards",
        group: "dashboard",
        icon: "mdi-view-dashboard-outline",
        color: "purple",
        menu: true,
        requiresAuth: true,
      },
      children: [
        {
          path: "iaos",
          name: "IncidentOverview",
          meta: { title: "IAOs", group: "type" },
          component: () => import("@/dashboard/incident/IncidentOverview.vue"),
        },
        {
          path: "cases",
          name: "CaseOverview",
          meta: { title: "OCOs", group: "type" },
          component: () => import("@/dashboard/case/CaseOverview.vue"),
        },
        {
          path: "tasks",
          name: "TaskOverview",
          meta: { title: "Tasks", group: "type" },
          component: () => import("@/dashboard/task/TaskOverview.vue"),
        },
        {
          path: "data",
          name: "DataOverview",
          meta: { title: "Data Catalog", group: "type" },
          component: () => import("@/dashboard/data/DataOverview.vue"),
        },
        {
          path: "People",
          name: "PeopleOverview",
          meta: { title: "People", group: "type" },
          component: () => import("@/dashboard/people/PeopleOverview.vue"),
        },
        {
          path: "Feedback",
          name: "FeedbackOverview",
          meta: { title: "Feedback", group: "type" },
          component: () => import("@/dashboard/feedback/FeedbackOverview.vue"),
        },
      ],
    },
    {
      path: "iaos",
      component: DefaultLayout,
      name: "IAOs",
      meta: {
        title: "IAOs",
        icon: "mdi-domain",
        color: "red",
        group: "iaos",
        requiresAuth: true,
        menu: true,
        showEditSheet: false,
      },
      redirect: { name: "IncidentTable" },
      children: [
        {
          path: "/:organization/iaos",
          name: "IncidentTable",
          meta: { title: "List" },
          component: () => import("@/incident/Table.vue"),
          children: [
            {
              path: "/:organization/iaos/:name",
              name: "IncidentTableEdit",
              component: () => import("@/incident/EditSheet.vue"),
              props: true,
              meta: {
                showEditSheet: true,
              },
            },
            {
              path: "/:organization/iaos/:name/view",
              name: "IncidentTableView",
              component: () => import("@/incident/IncidentPage.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "cases",
      component: DefaultLayout,
      name: "OCOs",
      meta: {
        title: "OCOs",
        icon: "mdi-briefcase-search-outline",
        color: "warning",
        group: "cases",
        requiresAuth: true,
        menu: true,
        showEditSheet: false,
      },
      redirect: { name: "CaseTable" },
      children: [
        {
          path: "/:organization/cases",
          name: "CaseTable",
          meta: { title: "List" },
          component: () => import("@/case/Table.vue"),
          children: [
            {
              path: "/:organization/cases/:name",
              name: "CaseTableEdit",
              component: () => import("@/case/EditSheet.vue"),
              props: true,
              meta: {
                showEditSheet: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "signals",
      component: DefaultLayout,
      name: "ASOs",
      meta: {
        title: "ASOs",
        icon: "mdi-signal",
        color: "teal",
        group: "signals",
        requiresAuth: true,
        menu: true,
        showEditSheet: false,
      },
      redirect: { name: "SignalInstanceTable" },
      children: [
        {
          path: "/:organization/signals",
          name: "SignalInstanceTable",
          meta: { title: "List" },
          component: () => import("@/signal/TableInstance.vue"),
        },
      ],
    },
    {
      path: "commits",
      component: DefaultLayout,
      name: "commits",
      meta: {
        title: "Commits",
        icon: "mdi-source-branch-plus",
        color: "blue",
        group: "commits",
        requiresAuth: true,
        menu: true,
        showEditSheet: false,
      },
      redirect: { name: "CommitTable" },
      children: [
        {
          path: "/:organization/commits",
          name: "CommitTable",
          meta: { title: "List" },
          component: () => import("@/commit/Table.vue"),
          children: [
            {
              path: "/:organization/commits/:name",
              name: "CommitTableEdit",
              component: () => import("@/commit/NewEditSheet.vue"),
              props: true,
              meta: {
                showEditSheet: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "oncall",
      component: DefaultLayout,
      name: "IAO Cadence",
      meta: {
        title: "IAO Cadence",
        icon: "mdi-transit-connection-variant",
        color: "indigo",
        group: "IAO Cadence",
        requiresAuth: true,
        menu: true,
      },
      redirect: { name: "OncallHandoffTable" },
      children: [
        {
          path: "/:organization/oncall/handoffs",
          name: "OncallHandoffTable",
          meta: { title: "IAO Handoffs", group: "IAO Cadence" },
          component: () => import("@/oncall/handoff/Table.vue"),
          children: [
            {
              path: "/:organization/oncall/handoffs/:id",
              name: "OnCallHandoffTableEdit",
              component: () => import("@/oncall/handoff/NewEditSheet.vue"),
              props: true,
              meta: {
                showEditSheet: true,
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/:organization/oncall/preshift_preps",
          name: "OncallPreshiftPrepTable",
          meta: { title: "IAO Preps", group: "IAO Cadence" },
          component: () => import("@/oncall/preshift_prep/Table.vue"),
          children: [
            {
              path: "/:organization/oncall/preshift_preps/:id",
              name: "OncallPreshiftPrepTableEdit",
              component: () => import("@/oncall/preshift_prep/NewEditSheet.vue"),
              props: true,
              meta: {
                showEditSheet: true,
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "data",
      component: DefaultLayout,
      name: "Data Catalog",
      redirect: { name: "SourceTable" },
      meta: {
        title: "ADO Catalog",
        icon: "mdi-database-import-outline",
        color: "blue",
        group: "ADO Catalog",
        menu: true,
        requiresAuth: true,
      },
      children: [
        {
          path: "/:organization/data/sources",
          name: "SourceTable",
          meta: { title: "Sources", group: "ADO Catalog" },
          component: () => import("@/data/source/Table.vue"),
        },
        {
          path: "/:organization/data/sources/:name/:tab",
          name: "SourceDetail",
          meta: { title: "Source Detail" },
          component: () => import("@/data/source/Detail.vue"),
        },
        {
          path: "/:organization/data/queries",
          name: "QueryTable",
          meta: { title: "Observability Queries", group: "ADO Catalog" },
          component: () => import("@/data/query/Table.vue"),
        },
        {
          path: "/:organization/data/commands",
          name: "CommandTable",
          meta: { title: "Commands", group: "ADO Catalog" },
          component: () => import("@/data/command/Table.vue"),
        },
        {
          path: "/:organization/data/weblinks",
          name: "WebLinkTable",
          meta: { title: "Observabilty Links", group: "ADO Catalog" },
          component: () => import("@/data/weblink/Table.vue"),
        },
        {
          path: "/:organization/data/bestpractices",
          name: "BestPracticeTable",
          meta: { title: "Best Practices", group: "ADO Catalog" },
          component: () => import("@/data/bestpractice/Table.vue"),
        }
      ],
    },
    {
      path: "tasks",
      component: DefaultLayout,
      name: "tasks",
      meta: {
        title: "IAO Tasks",
        icon: "mdi-file-tree",
        color: "green",
        group: "tasks",
        menu: true,
        requiresAuth: true,
        showEditSheet: false,
      },
      redirect: { name: "TaskTable" },
      children: [
        {
          path: "/:organization/tasks",
          name: "TaskTable",
          meta: { title: "List" },
          component: () => import("@/task/Table.vue"),
          children: [
            {
              path: "/:organization/tasks/:id",
              name: "TaskTableEdit",
              component: () => import("@/task/NewEditSheet.vue"),
              props: true,
              meta: {
                showEditSheet: true,
                title: "Edit",
              },
            }
          ],
        },
      ],
    },
    {
      path: "forms",
      component: DefaultLayout,
      name: "forms",
      meta: {
        title: "IAO Forms",
        icon: "mdi-form-textbox",
        group: "forms",
        color: "purple",
        menu: true,
        requiresAuth: true,
        showChip: true,
        chipText: "Preview",
      },
      redirect: { name: "FormsTable" },
      children: [
        {
          path: "/:organization/forms",
          name: "FormsTable",
          meta: { title: "Forms" },
          component: () => import("@/forms/table/Table.vue"),
        },
      ],
    },
    {
      path: "feedback",
      component: DefaultLayout,
      name: "feedback",
      redirect: { name: "FeedbackTable" },
      meta: {
        title: "Feedback",
        icon: "mdi-circle-multiple-outline",
        color: "cyan",
        group: "feedback",
        menu: true,
        requiresAuth: true,
      },
      children: [
        {
          path: "/:organization/feedback",
          name: "FeedbackTable",
          meta: { title: "IAO Feedback", group: "type" },
          component: () => import("@/feedback/incident/Table.vue"),
        },
        {
          path: "/:organization/servicefeedback/",
          name: "ServiceFeedbackTable",
          meta: { title: "IAO Cadence Feedback", group: "type" },
          component: () => import("@/feedback/service/Table.vue"),
        }
      ],
    },
    {
      path: "settings",
      component: DefaultLayout,
      name: "settings",
      meta: {
        title: "Settings",
        icon: "mdi-cogs",
        color: "pink",
        group: "settings",
        menu: true,
        requiresAuth: true,
      },
      redirect: { name: "ProjectTable" },
      children: [
        {
          path: "projects",
          name: "ProjectTable",
          meta: {
            title: "Projects",
            subMenu: "organization",
            group: "organization",
          },
          component: () => import("@/project/Table.vue"),
        },
        {
          path: "members",
          name: "OrganizationMemberTable",
          meta: { title: "Members", subMenu: "organization", group: "organization" },
          component: () => import("@/organization/OrganizationMemberTable.vue"),
        },
        {
          path: "projects",
          meta: {
            title: "Project Settings",
            group: "settings",
          },
          name: "ProjectSettings",
          redirect: { name: "PluginTable" },
        },
        ...withPrefix("projects/", [
          {
            path: "plugins",
            name: "PluginTable",
            meta: { title: "Plugins", subMenu: "project", group: "general" },
            component: () => import("@/plugin/Table.vue"),
          },
          {
            path: "notifications",
            name: "NotificationTable",
            meta: { title: "Notifications", subMenu: "project", group: "general" },
            component: () => import("@/notification/Table.vue"),
          },
          {
            path: "searchFilters",
            name: "SearchFilterTable",
            meta: { title: "Search Filters", subMenu: "project", group: "general" },
            component: () => import("@/search/Table.vue"),
          },
          {
            path: "workflows",
            name: "WorkflowTable",
            meta: { title: "Workflows", subMenu: "project", group: "general" },
            component: () => import("@/workflow/Table.vue"),
          },
          {
            path: "apiKeys",
            name: "ApiKeyTable",
            meta: { title: "API Keys", subMenu: "project", group: "general" },
            component: () => import("@/apiKeys/Table.vue"),
          },
          {
            path: "incidentTypes",
            name: "IncidentTypeTable",
            meta: { title: "Types", subMenu: "project", group: "IAO" },
            component: () => import("@/incident/type/Table.vue"),
          },
          {
            path: "incidentPriorities",
            name: "IncidentPriorityTable",
            meta: { title: "Priorities", subMenu: "project", group: "IAO" },
            component: () => import("@/incident/priority/Table.vue"),
          },
          {
            path: "incidentSeverities",
            name: "IncidentSeverityTable",
            meta: { title: "Severities", subMenu: "project", group: "IAO" },
            component: () => import("@/incident/severity/Table.vue"),
          },
          {
            path: "incidentCostTypes",
            name: "IncidentCostTypesTable",
            meta: { title: "Cost Types", subMenu: "project", group: "IAO" },
            component: () => import("@/incident_cost_type/Table.vue"),
          },
          {
            path: "CostModels",
            name: "CostModelTable",
            meta: {
              title: "Cost Models",
              subMenu: "project",
              group: "IAO",
              showChip: true,
              chipText: "Preview",
            },
            component: () => import("@/cost_model/Table.vue"),
          },
          {
            path: "incidentRoles",
            name: "IncidentRolesTable",
            meta: { title: "Roles", subMenu: "project", group: "IAO" },
            component: () => import("@/incident_role/Table.vue"),
          },
          {
            path: "caseTypes",
            name: "CaseTypeTable",
            meta: { title: "Types", subMenu: "project", group: "OCO" },
            component: () => import("@/case/type/Table.vue"),
          },
          {
            path: "casePriorities",
            name: "casePriorityTable",
            meta: { title: "Priorities", subMenu: "project", group: "OCO" },
            component: () => import("@/case/priority/Table.vue"),
          },
          {
            path: "caseSeverities",
            name: "caseSeverityTable",
            meta: { title: "Severities", subMenu: "project", group: "OCO" },
            component: () => import("@/case/severity/Table.vue"),
          },
          {
            path: "signals",
            name: "SignalDefinitionTable",
            meta: { title: "Definitions", subMenu: "project", group: "signal" },
            component: () => import("@/signal/Table.vue"),
          },
          {
            path: "entity/types",
            name: "EntityTypeTable",
            meta: { title: "Entity Types", subMenu: "project", group: "signal" },
            component: () => import("@/entity_type/Table.vue"),
          },
          {
            path: "services",
            name: "ServiceTable",
            meta: { title: "Services", subMenu: "project", group: "contact" },
            component: () => import("@/service/Table.vue"),
          },
          {
            path: "individuals",
            name: "IndividualTable",
            meta: { title: "Individuals", subMenu: "project", group: "contact" },
            component: () => import("@/individual/Table.vue"),
          },
          {
            path: "teams",
            name: "TeamTable",
            meta: { title: "Teams", subMenu: "project", group: "contact" },
            component: () => import("@/team/Table.vue"),
          },
          {
            path: "templates",
            name: "TemplateTable",
            meta: { title: "Templates", subMenu: "project", group: "documentation" },
            component: () => import("@/document/template/TemplateTable.vue"),
          },
          {
            path: "references",
            name: "ReferenceTable",
            meta: { title: "References", subMenu: "project", group: "documentation" },
            component: () => import("@/document/reference/ReferenceTable.vue"),
          },
          {
            path: "runbooks",
            name: "RunbookTable",
            meta: { title: "Runbooks", subMenu: "project", group: "documentation" },
            component: () => import("@/document/runbook/RunbookTable.vue"),
          },
          {
            path: "tagTypes",
            name: "TagTypeTable",
            meta: { title: "Tag Types", subMenu: "project", group: "knowledge" },
            component: () => import("@/tag_type/Table.vue"),
          },
          {
            path: "tags",
            name: "TagTable",
            meta: { title: "Tags", subMenu: "project", group: "knowledge" },
            component: () => import("@/tag/Table.vue"),
          },
          {
            path: "definitions",
            name: "DefinitionTable",
            meta: { title: "Definitions", subMenu: "project", group: "knowledge" },
            component: () => import("@/definition/Table.vue"),
          },
          {
            path: "terms",
            name: "TermTable",
            meta: { title: "Terms", subMenu: "project", group: "knowledge" },
            component: () => import("@/term/Table.vue"),
          },
          {
            path: "weblinks",
            name: "WebLinkTable",
            meta: { title: "Observability Links", subMenu: "project", group: "knowledge" },
            component: () => import("@/data/weblink/Table.vue"),
          },
          {
            path: "commands",
            name: "CommandTable",
            meta: { title: "Commands", subMenu: "project", group: "knowledge" },
            component: () => import("@/data/command/Table.vue"),
          },
          {
            path: "source/types",
            name: "SourceTypeTable",
            meta: { title: "Source Types", subMenu: "project", group: "data" },
            component: () => import("@/data/source/type/Table.vue"),
          },
          {
            path: "source/environments",
            name: "SourceEnvironmentTable",
            meta: { title: "Source Environments", subMenu: "project", group: "data" },
            component: () => import("@/data/source/environment/Table.vue"),
          },
          {
            path: "source/statuses",
            name: "SourceStatusTable",
            meta: { title: "Source Statuses", subMenu: "project", group: "data" },
            component: () => import("@/data/source/status/Table.vue"),
          },
          {
            path: "source/transports",
            name: "SourceTransportTable",
            meta: { title: "Source Transports", subMenu: "project", group: "data" },
            component: () => import("@/data/source/transport/Table.vue"),
          },
          {
            path: "source/dataFormats",
            name: "SourceDataFormatTable",
            meta: { title: "Source Data Formats", subMenu: "project", group: "data" },
            component: () => import("@/data/source/dataFormat/Table.vue"),
          },
        ]),
      ],
    },
    {
      path: "search",
      name: "GlobalSearch",
      component: DefaultLayout,
      meta: {
        title: "Search",
        icon: "view_compact",
        group: "search",
        noMenu: true,
        requiresAuth: true,
      },
      redirect: { name: "ResultList" },
      children: [
        {
          path: "results",
          name: "ResultList",
          meta: { name: "Results" },
          component: () => import("@/search/ResultList.vue"),
        },
      ],
    },
  ]),
]
