<template>
  <v-app>
    <div class="app-root">
      <loading v-if="loading"/>
      <router-view />
      <v-snackbar
        bottom
        right
        :value="updateExists"
        :timeout="-1"
        color="tips"
        class="update-snackbar"
      >
        An update is available
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="refreshApp"> Update </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import update from "./mixins/update"
import Loading from "./components/Loading.vue";
import { mapFields, mapState } from "vuex-map-fields";

export default {
  components: {
    Loading,
  },
  data() {
    return {}
  },
  methods: {
    updateTitle(pageTitle) {
      document.title = pageTitle ? `${pageTitle}` : 'UnStruct AI';
    }
  },
  provide() {
    return {
      updateTitle: this.updateTitle
    };
  },
  computed: {
    ...mapFields("app", ["loading"]),
  },
  created() {
    // Set theme
    const isDark = localStorage.getItem("dark_theme") === "true"
    this.$vuetify.theme.dark = isDark
  },
  mixins: [update],
}
</script>

<style scoped>
.update-snackbar {
  z-index: 9999 !important;
}
.v-application {
  background-color: var(--v-background-base) !important;
}
</style>
