<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div style="position: relative;">
  <v-app-bar v-if="isAppBarVisible" clipped-left clipped-right app flat class="v-bar--underline" color="background0">
    <organization-create-edit-dialog />
    <change-password-dialog />
    <!--<v-app-bar-nav-icon @click="handleDrawerToggle" />-->
    <router-link :to="{ name: 'chat' }" style="text-decoration: none">
      <div class="logo" style="margin-top: 5px">
        <img src="/static/unstruct-logo-new.png" width="55" height="55"   />
      </div>
    </router-link>
    <v-spacer />
    <v-text-field
      v-model="queryString"
      hide-details
      prepend-inner-icon="search"
      label="Search"
      clearable
      solo-inverted
      flat
      @keyup.enter="performSearch()"
    />
    <v-spacer />
    <v-toolbar-items>
      <v-list-item>
      <div class="pa-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small color="purple" block :to="{ name: 'entities' }" v-on="on">
              <v-icon small color="white">mdi-creation</v-icon>
            </v-btn>
          </template>
          <span>R/T IAOs</span>
        </v-tooltip>
      </div>
      <div class="pa-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small color="error" block :to="{ name: 'report' }" v-on="on">
              <v-icon small color="white">mdi-domain-plus</v-icon>
            </v-btn>
          </template>
          <span>Create a new IAO</span>
        </v-tooltip>
      </div>
      <div class="pa-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="warning"
              block
              :to="{ name: 'caseReport' }"
              v-on="on"
            >
              <v-icon small color="white">mdi-briefcase-plus</v-icon>
            </v-btn>
          </template>
          <span>Create a new OCO</span>
        </v-tooltip>
      </div>
      <div class="pa-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="success"
              block
              @click="toggleDarkTheme"
              v-on="on"
            >
              <v-icon small color="white">mdi-theme-light-dark</v-icon>
            </v-btn>
          </template>
          <span>Toggle Dark Theme</span>
        </v-tooltip>
      </div>
      </v-list-item>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-avatar>
              <v-icon color="primary">account_circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card width="400">
          <v-list>
            <v-list-item class="px-2">
              <v-list-item-avatar>
                <v-icon color="primary"> account_circle </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="title" v-text="currentUser().display_name || anonymousUser">
                </v-list-item-title>
                <v-list-item-subtitle> {{ currentUser().display_name || anonymousUser }} </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="logout()">
                      <v-icon>logout</v-icon>
                    </v-btn>
                  </template>
                  <span>Logout</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader>Organizations</v-subheader>
            <v-list-item v-for="(item, i) in organizations" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="showCreateEditDialog(item)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Organization</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="switchOrganizations(item.slug)" icon v-on="on">
                      <v-icon>mdi-swap-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <span>Switch Organization</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn icon @click="toggleAppBarVisibility" style="margin-right: 20px;">
        <v-icon>{{ isAppBarVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
    </v-toolbar-items>
  </v-app-bar>
  <v-btn icon @click="toggleAppBarVisibility" style="position: fixed; z-index: 1000; right: 10px; bottom: 1px;">
      <v-icon>{{ isAppBarVisible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import UserApi from "@/auth/api"

import Util from "@/util"
import OrganizationApi from "@/organization/api"
import OrganizationCreateEditDialog from "@/organization/CreateEditDialog.vue"
import ChangePasswordDialog from "@/auth/ChangePassword.vue"
import SearchUtils from "@/search/utils"

export default {
  name: "AppToolbar",
  data() {
    // Check if the current environment is staging based on the hostname
    const isStaging = window.location.hostname.includes('incident-stage') || window.location.hostname.includes('incident-dev');
    const baseDomain = isStaging ? 'unstructai.com' : 'unstruct.ai';

    // Define your applications with URLs dynamically adjusted based on the environment
    const apps = [
      { id: 1, name: 'unstruct.ai On Call', url: `https://${isStaging ? 'alert-stage' : 'alert'}.${baseDomain}`, icon: 'mdi-phone-in-talk' },
      { id: 2, name: 'unstruct.ai DFIR & SOC', url: `https://${isStaging ? 'storycurve-stage' : 'storycurve'}.${baseDomain}`, icon: 'mdi-strategy' },
      { id: 3, name: 'unstruct.ai StatusPage', url: `https://${isStaging ? 'status-stage' : 'status'}.${baseDomain}`, icon: 'mdi-list-status' },
    ];

    return {
      organizations: [],
      apps,
      isAppBarVisible: true
    };
  },
  components: {
    OrganizationCreateEditDialog,
    ChangePasswordDialog,
  },
  computed: {
    anonymousUser() {
      return "Anonymous"
    },
    queryString: {
      set(query) {
        this.$store.dispatch("search/setQuery", query)
      },
      get() {
        return this.$store.state.query.q
      },
    },
  },
  methods: {
    fetchOrganizationData() {
      const filterOptions = SearchUtils.createParametersFromTableOptions({
        itemsPerPage: 50,
        sortBy: ["name"],
        descending: [false],
      });
      OrganizationApi.getAll(filterOptions)
        .then(response => {
          this.currentOrganization = response.data.items[0];
          this.organizations = response.data.items;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    toggleAppBarVisibility() {
        this.isAppBarVisible = !this.isAppBarVisible;
      },
    waitForTokenToSet() {
      return new Promise(resolve => {
        const checkToken = () => {
          if (localStorage.getItem("token") && localStorage.getItem("organization")) {
            resolve();
          } else {
            setTimeout(checkToken, 100);
          }
        };
        checkToken();
      });
    },
    handleDrawerToggle() {
      this.$store.dispatch("app/toggleDrawer")
    },
    handleFullScreen() {
      Util.toggleFullScreen()
    },
    performSearch() {
      this.$store.dispatch("search/getResults", this.$store.state.query)
      this.$router.push({ name: "GlobalSearch" })
    },
    toggleDarkTheme() {
      const newTheme = !this.$vuetify.theme.dark
      this.$vuetify.theme.dark = newTheme
      localStorage.setItem("dark_theme", newTheme.toString())

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    switchOrganizations(slug) {
      // Set the new organization in localStorage
      localStorage.setItem("organization", slug);

      // Update the Vuex store
      this.$store.commit("auth/SET_CURRENT_ORGANIZATION", slug);

      // Get the current URL
      let currentUrl = new URL(window.location.href);

      // Split the pathname into parts
      let pathParts = currentUrl.pathname.split("/").filter(Boolean);

      // The organization should be the first part of the path
      if (pathParts.length > 0) {
        pathParts[0] = slug;
      } else {
        pathParts.unshift(slug);
      }

      // Reconstruct the pathname
      currentUrl.pathname = "/" + pathParts.join("/");

      // Update the URL and reload the page
      window.location.href = currentUrl.href;
    },
    openApp(url) {
      window.open(url, '_blank');
    },
    upgradeToPaidPlan() {
      this.$router.push({ name: "upgradePlan" })
    },
    ...mapState("auth", ["currentUser", "userAvatarUrl"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("auth", ["showChangePasswordDialog"]),
    ...mapActions("search", ["setQuery"]),
    ...mapActions("organization", ["showCreateEditDialog"]),
    ...mapMutations("search", ["SET_QUERY"]),
  },

  created() {
    this.error = null
    this.loading = "error"

    let theme = localStorage.getItem("dark_theme")
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    } else {
      this.$vuetify.theme.dark = true
    }

    this.waitForTokenToSet()
      .then(() => {
        this.fetchOrganizationData()
      })
      .catch(error => {
        console.log(error)
      })
  },
}
</script>

<style scoped>
.upgrade-chip {
  cursor: pointer;
  align-items: center;
}
.v-menu__content {
  z-index: 2000; /* Ensure this is high enough to be on top of other content */
}

</style>
