const actions = {
    copyToClipboardSuccess({ commit }) {
        commit(
            "notification_backend/addBeNotification",
            { text: "Copied to clipboard.", type: "success" },
            { root: true }
        )
    },
}

export default {
    namespaced: true,
    actions,
}
