/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import API from "@/api"

const resource = "users"

export default {
  getAll(options) {
    return API.get(`/${resource}`, { params: { ...options } })
  },
  get(userId) {
    return API.get(`/${resource}/${userId}`)
  },
  update(userId, payload) {
    return API.put(`/${resource}/${userId}`, payload)
  },
  create(payload) {
    return API.post(`/${resource}`, payload)
  },
  getUserInfo() {
    const token = localStorage.getItem("token");
    const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

    if (token) {
      return API.get(`/auth/me`);
    } else if (unstruct_anonymous_key) {
      return API.get(`/auth/anonymous/me/${unstruct_anonymous_key}`);
    } else {
      return Promise.reject(new Error("No authentication or temporary session found"));
    }
  },
  login(email, password) {
    return API.post(`/auth/login`, { email: email, password: password })
  },
  sendVerificationCode(email) {
    return API.post("/auth/login", { email });
  },
  verifyCode(email, code) {
    return API.post("/auth/verify", { email, code });
  },
  oauthLogin() {
    window.location = '/api/v1/default/auth/google';
  },
  postOauthLogin(token) {
    return API.post(`/auth/postoauthlogin`, { token: token })
  },
  changePassword(userId, payload) {
    return API.put(`/${resource}/${userId}`, payload)
  },
}
