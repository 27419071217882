import API from "@/api"

const resource = "/organizations"

export default {
  getAll(options) {
    const token = localStorage.getItem("token");
    const unstruct_anonymous_key = localStorage.getItem("unstruct_anonymous_key");

    if (token) {
      return API.get(`${resource}`, { params: { ...options } });
    } else if (unstruct_anonymous_key) {
      return API.get(`${resource}/anonymous/${unstruct_anonymous_key}`, { params: { ...options } });
    } else {
      return Promise.reject(new Error("No authentication or temporary session found"));
    }
  },

  get(organizationId) {
    return API.get(`${resource}/${organizationId}`)
  },

  create(payload) {
    return API.post(`${resource}`, payload)
  },

  update(organizationId, payload) {
    return API.put(`${resource}/${organizationId}`, payload)
  },

  delete(organizationId) {
    return API.delete(`${resource}/${organizationId}`)
  },
}
