/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField, updateField } from "vuex-map-fields";
import { debounce } from "lodash";

import SearchUtils from "@/search/utils";
import ApiKeysApi from "@/apiKeys/api";

const getDefaultSelectedState = () => {
    return {
        key: null,
        id: null,
        description: null,
        loading: false,
        project: null,
    };
};

const state = {
    selected: {
        ...getDefaultSelectedState(),
    },
    dialogs: {
        showCreateEdit: false,
        showRemove: false,
    },
    table: {
        rows: {
            items: [],
            total: null,
        },
        options: {
            q: "",
            page: 1,
            itemsPerPage: 10,
            sortBy: ["expiration"],
            descending: [false],
            filters: {
                project: [],
            },
        },
        loading: false,
    },
};

const getters = {
    getField,
};

const actions = {
    generateApiKey({ commit, dispatch }, payload) {
        ApiKeysApi.create(payload)
            .then((response) => {
                commit(
                    "notification_backend/addBeNotification",
                    { text: "API Key created successfully.", type: "success" },
                    { root: true }
                );
                dispatch("getAll");
            })
            .catch((error) => {
                console.error("Error generating new API Key: ", error);
            });
    },

    getAll: debounce(({ commit, state }) => {
        commit("SET_TABLE_LOADING", true);
        let params = SearchUtils.createParametersFromTableOptions(
            { ...state.table.options },
            "ApiKey"
        );
        return ApiKeysApi.getAll(params)
            .then((response) => {
                commit("SET_TABLE_LOADING", false);
                commit("SET_TABLE_ROWS", response.data);
            })
            .catch(() => {
                commit("SET_TABLE_LOADING", false);
            });
    }, 500),
    createEditShow({ commit }, apiKey) {
        commit("SET_DIALOG_CREATE_EDIT", true);
        if (apiKey) {
            commit("SET_SELECTED", apiKey);
        }
    },
    removeShow({ commit }, apiKey) {
        commit("SET_DIALOG_DELETE", true);
        commit("SET_SELECTED", apiKey);
    },
    closeCreateEdit({ commit }) {
        commit("SET_DIALOG_CREATE_EDIT", false);
        commit("RESET_SELECTED");
    },
    closeRemove({ commit }) {
        commit("SET_DIALOG_DELETE", false);
        commit("RESET_SELECTED");
    },
    save({ commit, dispatch, state }) {
        commit("SET_SELECTED_LOADING", true);
        if (!state.selected.id) {
            return ApiKeysApi.create(state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false);
                    dispatch("closeCreateEdit");
                    dispatch("getAll");
                    // notification logic here
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false);
                });
        } else {
            return ApiKeysApi.update(state.selected.id, state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false);
                    dispatch("closeCreateEdit");
                    dispatch("getAll");
                    // notification logic here
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false);
                });
        }
    },
    remove({ commit, dispatch, state }) {
        return ApiKeysApi.delete(state.selected.id).then(() => {
            dispatch("closeRemove");
            dispatch("getAll");
            // notification logic here
        });
    },
};

const mutations = {
    updateField,
    SET_SELECTED(state, value) {
        state.selected = Object.assign(state.selected, value);
    },
    SET_SELECTED_LOADING(state, value) {
        state.selected.loading = value;
    },
    SET_TABLE_LOADING(state, value) {
        state.table.loading = value;
    },
    SET_TABLE_ROWS(state, value) {
        state.table.rows = value;
    },
    SET_DIALOG_CREATE_EDIT(state, value) {
        state.dialogs.showCreateEdit = value;
    },
    SET_DIALOG_DELETE(state, value) {
        state.dialogs.showRemove = value;
    },
    RESET_SELECTED(state) {
        state.selected = { ...getDefaultSelectedState() };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
