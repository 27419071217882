import Vue from "vue"
import { parseISO, formatISO } from "date-fns"
import moment from "moment-timezone"
const time_format = "YYYY-MM-DD HH:mm:ss"
const zones_to_show = ["America/Los_Angeles", "America/New_York"]

Vue.filter("formatDate", function (value) {
  if (value) {
    return formatISO(parseISO(value))
  }
})

Vue.filter("formatToTimeZones", function (value) {
  if (value) {
    let m = moment(value)
    return `UTC: ${value}\nPST: ${m
      .tz("America/Los_Angeles")
      .format("YYYY-MM-DD HH:mm:ss")}\nEST: ${m
        .tz("America/New_York")
        .format("YYYY-MM-DD HH:mm:ss")}`
  }
})


Vue.filter("formatRelativeDate", function (value) {
  if (value) {
    // Convert UTC time to local time
    const localTime = moment.utc(value).local();

    const now = moment();
    const diffSeconds = now.diff(localTime, 'seconds');

    const units = [
      { unit: 'year', seconds: 31536000 },
      { unit: 'month', seconds: 2592000 },
      { unit: 'week', seconds: 604800 },
      { unit: 'day', seconds: 86400 },
      { unit: 'hour', seconds: 3600 },
      { unit: 'minute', seconds: 60 },
      { unit: 'second', seconds: 1 }
    ];

    for (let { unit, seconds } of units) {
      const interval = Math.floor(diffSeconds / seconds);
      if (interval >= 1) {
        return moment.duration(-interval, unit).humanize(true);
      }
    }

    return 'just now';
  }
})

Vue.filter("initials", function (value) {
  if (value) {
    return value
      .split(" ")
      .map((n) => n[0])
      .slice(0, 2)
      .join("")
  }
})

Vue.filter("asString", function (value) {
  if (!value) return ""
  return value.toString()
})

Vue.filter("capitalize", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("toUSD", function (value) {
  if (value) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumSignificantDigits: 6,
    })
    return formatter.format(value)
  }
  return value
})

Vue.filter("toNumberString", function (value) {
  if (value) {
    var formatter = new Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 6,
    })
    return formatter.format(value)
  }
  return value
})

Vue.filter("deslug", function (value) {
  if (value) {
    return value
      .split("-")
      .slice(2)
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  }
})

Vue.filter("snakeToCamel", function (value) {
  if (value) {
    return value
      .split("_")
      .map(function (value) {
        return value.charAt(0).toUpperCase() + value.substring(1)
      })
      .join(" ")
  }
})

Vue.filter("commaString", function (value, key) {
  if (value) {
    return value
      .map(function (el) {
        return el[key]
      })
      .join(", ")
  }
})

export const activeRoles = function (value) {
  if (value) {
    let active_roles = value
      .filter((role) => !role.renounced_at)
      .map(function (role) {
        return role.role
      })
      .join(", ")
    if (active_roles) {
      return active_roles
    } else {
      return "Inactive"
    }
  }
}

Vue.filter("activeRoles", activeRoles)

Vue.filter("individualNames", function (value) {
  if (value) {
    return value
      .map(function (item) {
        return item.individual.name
      })
      .join(", ")
  }
})

export function formatToUTC(value) {
  if (value) {
    return moment(value).utc().format(time_format)
  }
}
