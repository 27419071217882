<template>
  <ValidationObserver v-slot="{ invalid, validated }">
    <v-dialog v-model="showChangePassword" max-width="600">
      <v-card flat outlined :loading="loading">
        <v-card-title> Change Password </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <ValidationProvider name="Change Password" rules="required" immediate>
                  <v-text-field
                    v-model="password"
                    :type="'password'"
                    label="New Password"
                    slot-scope="{ errors, valid }"
                    :error-messages="errors"
                    :success="valid"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-list-item two-line>
            <v-row align="center" justify="end">
              <v-btn
                color="info"
                :loading="loading"
                :disabled="invalid || !validated"
                @click="changeUserPassword()"
              >
                Change Password
                <template v-slot:loader>
                  <v-progress-linear indeterminate color="white" />
                </template>
              </v-btn>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"

extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      password: "",
    }
  },
  computed: {
    ...mapFields("auth", ["loading", "dialogs.showChangePassword", "currentUser"]),
  },
  methods: {
    ...mapActions("auth", ["changePassword"]),
    changeUserPassword() {
      this.changePassword({ password: this.password, id: this.currentUser.id })
    },
  },
}
</script>

<style scoped></style>
