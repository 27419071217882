/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField, updateField } from "vuex-map-fields"
import { debounce } from "lodash"

import SearchUtils from "@/search/utils"
import IncidentApi from "@/incident/api"
import router from "@/router"
import moment from "moment-timezone"
import StepsApi from '@/incident/steps/api';
import Vue from 'vue';

const getDefaultSelectedState = () => {
  return {
    cases: [],
    commander: null,
    commander_email: null,
    conference: null,
    conversation: null,
    created_at: null,
    closed_at: null,
    custom_fields: [],
    custom_checklist: [],
    description: null,
    documents: null,
    entities: [],
    events: null,
    id: null,
    incident_costs: null,
    incident_priority: null,
    incident_severity: null,
    incident_type: null,
    name: null,
    participants: [],
    project: null,
    queries: [],
    reported_at: null,
    reporter: null,
    assignee: null,
    postmortems: [],
    resolution: null,
    services: [],
    stable_at: null,
    status: null,
    statuspage: null,
    statuspage_id: null,
    storycurve_url: null,
    unstatus_url: null,
    storycurve_sketch_id: null,
    storage: null,
    tags: [],
    tasks: [],
    steps: [],
    terms: [],
    ticket: null,
    title: null,
    visibility: null,
    workflow_instances: null,
    weblinks: [],
    loading: false,
    currentEvent: {},
  }
}

const getDefaultReportState = () => {
  return {
    type: "tactical",
    tactical: {
      conditions: null,
      actions: null,
      needs: null,
    },
    executive: {
      current_status: null,
      overview: null,
      next_steps: null,
    },
  }
}

const state = {
  selected: {
    ...getDefaultSelectedState(),
  },
  dialogs: {
    showDeleteDialog: false,
    showEditSheet: false,
    showExport: false,
    showHandoffDialog: false,
    showNewSheet: false,
    showReportDialog: false,
    showEditEventDialog: false,
    showDeleteEventDialog: false,
    showStepAiSummaryDialog: false,
  },
  report: {
    ...getDefaultReportState(),
  },
  table: {
    rows: {
      items: [],
      total: null,
      selected: [],
    },
    options: {
      filters: {
        reporter: [],
        commander: [],
        incident_type: [],
        incident_priority: [],
        incident_severity: [],
        status: [],
        tag: [],
        project: [],
        tag_type: [],
        reported_at: {
          start: null,
          end: null,
        },
        closed_at: {
          start: null,
          end: null,
        },
      },
      q: "",
      page: 1,
      itemsPerPage: 25,
      sortBy: ["reported_at"],
      descending: [true],
    },
    loading: false,
    bulkEditLoading: false,
  },
  timeline_filters: {
    field_updates: false,
    assessment_updates: false,
    context_updates: false,
    user_curated_events: false,
    participant_updates: true,
    other_events: true,
    notification_events: false,
    ai_generated_events: true,
    resource_updates: true,
    step_updates: true,
    task_updates: true,
    conversation_events: true,

  },
  currentFilter: null,
  updateLoading: false,
  stepLoading: {},
  stepAiSummaryLoading: false,
  stepAiSummary: '',
}

const getters = {
  getField,
  tableOptions({ state }) {
    // format our filters
    return state.table.options
  },
}

const actions = {
  createPostmortemReport({ commit }, payload) {
    return IncidentApi.createPostmortemReport(payload.incidentId)
      .then(() => {
        commit(
          "notification_backend/addBeNotification",
          { text: "Postmortem (PIR) story will be available shortly", type: "success" },
          { root: true }
        )
      })
      .catch(() => {
        commit(
          "notification_backend/addBeNotification",
          { text: "Submission failed.", type: "error" },
          { root: true }
        )
      })
  },

  updateStep({ commit }, payload) {
    commit("SET_STEP_LOADING", { ...state.stepLoading, [payload.stepId]: true })
    return StepsApi.updateIncidentStep(payload.incidentId, payload.stepId, payload.step)
      .then(() => {
        // update the step in the store
        commit('UPDATE_STEP', payload);
        commit(
          "notification_backend/addBeNotification",
          { text: "Step updated successfully.", type: "success" },
          { root: true }
        )
      })
      .catch((error) => {
        console.log("Failed to update step", payload.stepId);
        console.log(error);
        commit(
          "notification_backend/addBeNotification",
          { text: "Failed to update step.", type: "error" },
          { root: true }
        )
      })
      .finally(() => {
        commit("SET_STEP_LOADING", { ...state.stepLoading, [payload.stepId]: false })
      });
  },
  copyToClipboardSuccess({ commit }) {
    commit(
      "notification_backend/addBeNotification",
      { text: "Copied to clipboard.", type: "success" },
      { root: true }
    )
  },

  addComment({ commit }, payload) {
    return IncidentApi.addComment(payload.incidentId, payload.comment)
      .then(() => {
        commit(
          "notification_backend/addBeNotification",
          { text: "Comment added successfully.", type: "success" },
          { root: true }
        )
        commit("ADD_COMMENT", payload.comment)
      })
      .catch(() => {
        commit(
          "notification_backend/addBeNotification",
          { text: "Failed to add comment.", type: "error" },
          { root: true }
        )
      })
  },
  getAll: debounce(({ commit, state }) => {
    commit("SET_TABLE_LOADING", "primary")
    let params = SearchUtils.createParametersFromTableOptions(
      { ...state.table.options },
      "Incident"
    )
    return IncidentApi.getAll(params)
      .then((response) => {
        commit("SET_TABLE_LOADING", false)
        commit("SET_TABLE_ROWS", response.data)
      })
      .catch(() => {
        commit("SET_TABLE_LOADING", false)
      })
  }, 500),
  get({ commit, state }) {
    // noop if no selected id available
    if (state.selected.id) {
      return IncidentApi.get(state.selected.id).then((response) => {
        commit("SET_SELECTED", response.data)
      })
    }
  },
  getDetails({ commit, state }, payload) {
    commit("SET_SELECTED_LOADING", true)
    if ("id" in payload) {
      return IncidentApi.get(state.selected.id).then((response) => {
        commit("SET_SELECTED", response.data)
        commit("SET_SELECTED_LOADING", false)
      })
    } else if ("name" in payload) {
      // this is kinda dirty
      return IncidentApi.getAll({
        filter: JSON.stringify([
          { and: [{ model: "Incident", field: "name", op: "==", value: payload.name }] },
        ]),
      }).then((response) => {
        if (response.data.items.length) {
          // get the full data set
          return IncidentApi.get(response.data.items[0].id).then((response) => {
            commit("SET_SELECTED", response.data)
            commit("SET_SELECTED_LOADING", false)
          })
        } else {
          commit(
            "notification_backend/addBeNotification",
            {
              text: `IAO '${payload.name}' could not be found.`,
              type: "error",
            },
            { root: true }
          )
          commit("SET_DIALOG_SHOW_EDIT_SHEET", false)
        }
        commit("SET_SELECTED_LOADING", false)
      })
    }
  },
  getStepAiSummary({ commit }, payload) {
    commit("SET_STEP_AI_SUMMARY_LOADING", true);
    return StepsApi.getStepAiSummary(payload.incidentId, payload.stepId)
      .then((response) => {
        commit("SET_DIALOG_SHOW_STEP_AI_SUMMARY", true);
        commit("SET_AI_STEP_SUMMARY", response.data);
        commit("SET_STEP_AI_SUMMARY_LOADING", false);
      })
      .catch((error) => {
        console.log("Failed to get step AI summary", payload.stepId);
        console.log(error);
        commit(
          "notification_backend/addBeNotification",
          { text: "Failed to get step AI summary.", type: "error" },
          { root: true }
        )
      })
      .finally(() => {
        commit("SET_STEP_AI_SUMMARY_LOADING", false);
      });
  },
  getSimilar({ commit, state }, payload) {
    return IncidentApi.getSimilar(state.selected.id, payload).then((response) => {
      return response.data
    })
  },
  showNewSheet({ commit }, incident) {
    commit("SET_DIALOG_SHOW_NEW_SHEET", true)
    if (incident) {
      commit("SET_SELECTED", incident)
    }
  },
  closeNewSheet({ commit }) {
    commit("SET_DIALOG_SHOW_NEW_SHEET", false)
    commit("RESET_SELECTED")
  },
  showEditSheet({ commit }) {
    commit("SET_DIALOG_SHOW_EDIT_SHEET", true)
  },
  closeEditSheet({ commit }) {
    commit("SET_DIALOG_SHOW_EDIT_SHEET", false)
    commit("RESET_SELECTED")
    router.push({ name: "IncidentTable" })
  },
  showDeleteDialog({ commit }, incident) {
    commit("SET_DIALOG_DELETE", true)
    commit("SET_SELECTED", incident)
  },
  closeDeleteDialog({ commit }) {
    commit("SET_DIALOG_DELETE", false)
    commit("RESET_SELECTED")
  },
  showReportDialog({ commit }, incident) {
    commit("SET_DIALOG_REPORT", true)
    commit("SET_SELECTED", incident)
    state.report.tactical.actions = incident.tasks.reduce((result, task) => {
      if (task.status == "Resolved") {
        return result
      }
      return (result ? result + "\n" : "") + "- " + task.description
    }, "")
  },
  closeReportDialog({ commit }) {
    commit("SET_DIALOG_REPORT", false)
    commit("RESET_SELECTED")
  },
  showExport({ commit }) {
    commit("SET_DIALOG_SHOW_EXPORT", true)
  },
  closeExport({ commit }) {
    commit("SET_DIALOG_SHOW_EXPORT", false)
  },
  showHandoffDialog({ commit }, value) {
    commit("SET_DIALOG_SHOW_HANDOFF", true)
    commit("SET_SELECTED", value)
  },
  closeHandoffDialog({ commit }) {
    commit("SET_DIALOG_SHOW_HANDOFF", false)
    commit("RESET_SELECTED")
  },
  likeIncident({ commit }, incidentId) {
    // Simulate a successful API call with a Promise. TODO. KJ. Add real API call
    new Promise((resolve) => {
      setTimeout(() => resolve("success"), 500); // Simulate async operation with setTimeout
    }).then(() => {
      commit(
        "notification_backend/addBeNotification",
        { text: "This is gold dust; thanks for helping us improve! 🚀", type: "success" },
        { root: true }
      );
    });
  },
  dislikeIncident({ commit }, incidentId) {
    // Simulate a successful API call with a Promise. TODO. KJ. Add real API call
    new Promise((resolve) => {
      setTimeout(() => resolve("success"), 500);
    }).then(() => {
      commit(
        "notification_backend/addBeNotification",
        { text: "This is gold dust; thanks for helping us improve! 🚀", type: "success" },
        { root: true }
      );
    });
  },

  showEditEventDialog({ commit }, event) {
    state.selected.currentEvent = event
    commit("SET_DIALOG_EDIT_EVENT", true)
  },
  closeEditEventDialog({ commit }) {
    commit("SET_DIALOG_EDIT_EVENT", false)
  },
  showNewPreEventDialog({ commit }, started_at) {
    const newStartedAt = moment(started_at).add(1, "seconds").toISOString();
    const currentEventPayload = { started_at: newStartedAt, description: "" };
    commit("UPDATE_CURRENT_EVENT", currentEventPayload);
    commit("SET_DIALOG_EDIT_EVENT", true)
  },
  showNewEventDialog({ commit }, started_at) {
    const newStartedAt = moment(started_at).add(1, "seconds").toISOString();
    const currentEventPayload = { started_at: started_at, description: "" };
    commit("UPDATE_CURRENT_EVENT", currentEventPayload);
    commit("SET_DIALOG_EDIT_EVENT", true)
  },
  showDeleteEventDialog({ commit }, event) {
    state.selected.currentEvent = event
    commit("SET_DIALOG_DELETE_EVENT", true)
  },
  togglePin({ commit }, event) {
    state.selected.currentEvent = event
    state.selected.currentEvent.pinned = !state.selected.currentEvent.pinned
    IncidentApi.updateEvent(state.selected.id, state.selected.currentEvent).then(() => {
      IncidentApi.get(state.selected.id).then((response) => {
        commit("SET_SELECTED", response.data)
      })
      commit(
        "notification_backend/addBeNotification",
        { text: "Event updated successfully.", type: "success" },
        { root: true }
      )
    })
    commit("SET_DIALOG_EDIT_EVENT", false)
  },
  closeDeleteEventDialog({ commit }) {
    commit("SET_DIALOG_DELETE_EVENT", false)
  },
  storeNewEvent({ commit }) {
    IncidentApi.createNewEvent(state.selected.id, {
      source: "Incident Participant",
      description: state.selected.currentEvent.description,
      started_at: state.selected.currentEvent.started_at,
      type: "Custom event",
      details: {},
    }).then(() => {
      IncidentApi.get(state.selected.id).then((response) => {
        commit("SET_SELECTED", response.data)
      })
      commit(
        "notification_backend/addBeNotification",
        { text: "Event created successfully.", type: "success" },
        { root: true }
      )
    })
    commit("SET_DIALOG_EDIT_EVENT", false)
  },
  updateExistingEvent({ commit }) {
    IncidentApi.updateEvent(state.selected.id, state.selected.currentEvent).then(() => {
      IncidentApi.get(state.selected.id).then((response) => {
        commit("SET_SELECTED", response.data)
      })
      commit(
        "notification_backend/addBeNotification",
        { text: "Event updated successfully.", type: "success" },
        { root: true }
      )
    })
    commit("SET_DIALOG_EDIT_EVENT", false)
  },
  deleteEvent({ commit }) {
    IncidentApi.deleteEvent(state.selected.id, state.selected.currentEvent.uuid).then(() => {
      IncidentApi.get(state.selected.id).then((response) => {
        commit("SET_SELECTED", response.data)
      })
      commit(
        "notification_backend/addBeNotification",
        { text: "Event deleted successfully.", type: "success" },
        { root: true }
      )
    })
    commit("SET_DIALOG_DELETE_EVENT", false)
  },
  report({ commit, dispatch }) {
    commit("SET_SELECTED_LOADING", true)
    return IncidentApi.create(state.selected)
      .then((response) => {
        commit("SET_SELECTED", response.data)
        commit("SET_SELECTED_LOADING", false)
        var interval = setInterval(function () {
          if (state.selected.id) {
            dispatch("get")
          }

          // TODO this is fragile but we don't set anything as "created"
          if (state.selected.conversation) {
            clearInterval(interval)
          }
        }, 5000)
      })
      .catch(() => {
        commit("SET_SELECTED_LOADING", false)
      })
  },
  addResource({ commit, state }, resource) {
    commit("SET_SELECTED_LOADING", true);
    IncidentApi.addResource(state.selected.id, resource)
      .then((response) => {
        commit("SET_SELECTED_LOADING", false);
        commit(
          "notification_backend/addBeNotification",
          { text: "Resource added successfully.", type: "success" },
          { root: true }
        );
      })
      .catch((error) => {
        console.error("Error adding resource to incident:", error);
        commit("SET_SELECTED_LOADING", false);
        commit(
          "notification_backend/addBeNotification",
          { text: "Failed to add resource.", type: "error" },
          { root: true }
        );
      });
  },

  save({ commit, dispatch }) {
    commit("SET_SELECTED_LOADING", true)
    if (!state.selected.id) {
      return IncidentApi.create(state.selected)
        .then(() => {
          dispatch("closeNewSheet")
          dispatch("getAll")
          commit(
            "notification_backend/addBeNotification",
            { text: "Incident created successfully.", type: "success" },
            { root: true }
          )
          commit("SET_SELECTED_LOADING", false)
        })
        .catch(() => {
          commit("SET_SELECTED_LOADING", false)
        })
    } else {
      return IncidentApi.update(state.selected.id, state.selected)
        .then(() => {
          dispatch("closeEditSheet")
          dispatch("getAll")
          commit(
            "notification_backend/addBeNotification",
            { text: "Incident updated successfully.", type: "success" },
            { root: true }
          )
          commit("SET_SELECTED_LOADING", false)
        })
        .catch(() => {
          commit("SET_SELECTED_LOADING", false)
        })
    }
  },
  markStable({ commit, dispatch }, incidentId) {
    commit("SET_UPDATE_INCIDENT_LOADING", true)
    return IncidentApi.markStable(incidentId).then(() => {
      dispatch("getAll")
      dispatch("get")
      commit(
        "notification_backend/addBeNotification",
        { text: "Incident marked as stable. Running stable status workflows.", type: "success" },
        { root: true }
      )
      commit("SET_UPDATE_INCIDENT_LOADING", false)
    }
    ).catch(() => {
      commit("SET_UPDATE_INCIDENT_LOADING", false)
    })
  },
  markClosed({ commit, dispatch }, incidentId) {
    commit("SET_UPDATE_INCIDENT_LOADING", true)
    return IncidentApi.markClosed(incidentId).then(() => {
      dispatch("getAll")
      dispatch("get")
      commit(
        "notification_backend/addBeNotification",
        { text: "Incident marked as closed. Running closed status workflows.", type: "success" },
        { root: true }
      )
      commit("SET_UPDATE_INCIDENT_LOADING", false)
    })
      .catch(() => {
        commit("SET_UPDATE_INCIDENT_LOADING", false)
      })
  },
  saveBulk({ commit, dispatch }, payload) {
    commit("SET_BULK_EDIT_LOADING", true)
    return IncidentApi.bulkUpdate(state.table.rows.selected, payload)
      .then(() => {
        dispatch("getAll")
        commit(
          "notification_backend/addBeNotification",
          { text: "Incident(s) updated successfully.", type: "success" },
          { root: true }
        )
        commit("SET_BULK_EDIT_LOADING", false)
      })
      .catch(() => {
        commit("SET_BULK_EDIT_LOADING", false)
      })
  },
  deleteBulk({ commit, dispatch }) {
    commit("SET_BULK_EDIT_LOADING", true)
    return IncidentApi.bulkDelete(state.table.rows.selected)
      .then(() => {
        dispatch("getAll")
        commit(
          "notification_backend/addBeNotification",
          { text: "Incident(s) deleted successfully.", type: "success" },
          { root: true }
        )
        commit("SET_BULK_EDIT_LOADING", false)
      })
      .catch(() => {
        commit("SET_BULK_EDIT_LOADING", false)
      })
  },
  deleteIncident({ commit, dispatch }) {
    return IncidentApi.delete(state.selected.id).then(function () {
      dispatch("closeDeleteDialog")
      dispatch("getAll")
      commit(
        "notification_backend/addBeNotification",
        { text: "Incident deleted successfully.", type: "success" },
        { root: true }
      )
    })
  },
  createReport({ commit, dispatch }) {
    return IncidentApi.createReport(
      state.selected.id,
      state.report.type,
      state.report[state.report.type]
    ).then(function () {
      dispatch("closeReportDialog")
      dispatch("getAll")
      commit(
        "notification_backend/addBeNotification",
        { text: "Report created successfully.", type: "success" },
        { root: true }
      )
    })
  },
  resetSelected({ commit }) {
    commit("RESET_SELECTED")
  },
  joinIncident({ commit }, incidentId) {
    IncidentApi.join(incidentId, {}).then(() => {
      commit(
        "notification_backend/addBeNotification",
        { text: "You have successfully joined the incident.", type: "success" },
        { root: true }
      )
    })
  },
  subscribeToIncident({ commit }, incidentId) {
    IncidentApi.subscribe(incidentId, {}).then(() => {
      commit(
        "notification_backend/addBeNotification",
        {
          text: "You have successfully subscribed to the incident. You will receive all tactical reports about this incident via email.",
          type: "success",
        },
        { root: true }
      )
    })
  },
}

const mutations = {
  updateField,
  addIncidentCost(state, value) {
    state.selected.incident_costs.push(value)
  },
  ADD_RESOURCE_TO_INCIDENT(state, resource) {
    if (!state.selected.resources) {
      state.selected.resources = [];
    }
    state.selected.resources.push(resource);
  },
  removeIncidentCost(state, idx) {
    state.selected.incident_costs.splice(idx, 1)
  },
  SET_SELECTED(state, value) {
    state.selected = Object.assign(state.selected, value)
  },
  SET_TABLE_LOADING(state, value) {
    state.table.loading = value
  },
  SET_TABLE_ROWS(state, value) {
    // reset selected on table load
    value["selected"] = []
    state.table.rows = value
  },
  SET_DIALOG_SHOW_EDIT_SHEET(state, value) {
    state.dialogs.showEditSheet = value
  },
  SET_DIALOG_SHOW_NEW_SHEET(state, value) {
    state.dialogs.showNewSheet = value
  },
  SET_DIALOG_SHOW_EXPORT(state, value) {
    state.dialogs.showExport = value
  },
  SET_DIALOG_SHOW_STEP_AI_SUMMARY(state, value) {
    state.dialogs.showStepAiSummaryDialog = value
  },
  SET_DIALOG_SHOW_HANDOFF(state, value) {
    state.dialogs.showHandoffDialog = value
  },
  SET_DIALOG_DELETE(state, value) {
    state.dialogs.showDeleteDialog = value
  },
  SET_DIALOG_EDIT_EVENT(state, value) {
    state.dialogs.showEditEventDialog = value
  },
  SET_DIALOG_DELETE_EVENT(state, value) {
    state.dialogs.showDeleteEventDialog = value
  },
  SET_DIALOG_REPORT(state, value) {
    state.dialogs.showReportDialog = value
  },
  SET_AI_STEP_SUMMARY(state, value) {
    state.stepAiSummary = value;
  },
  RESET_SELECTED(state) {
    state.selected = Object.assign(state.selected, getDefaultSelectedState())
    state.report = Object.assign(state.report, getDefaultReportState())
  },
  SET_BULK_EDIT_LOADING(state, value) {
    state.table.bulkEditLoading = value
  },
  SET_SELECTED_LOADING(state, value) {
    state.selected.loading = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_STEP_LOADING(state, value) {
    state.stepLoading = value
  },
  SET_STEP_AI_SUMMARY_LOADING(state, value) {
    state.stepAiSummaryLoading = value
  },
  SET_SLA_REMINDER_LOADING(state, value) {
    state.slaReminderLoading = value
  },
  UPDATE_CURRENT_EVENT(state, payload) {
    state.selected.currentEvent = payload;
  },
  UPDATE_TASK_STATUS(state, payload) {
    const task = state.selected.tasks.find(task => task.id === payload.taskId);
    if (task) {
      task.status = payload.newStatus;
    }
  },
  SET_UPDATE_INCIDENT_LOADING(state, value) {
    state.updateLoading = value
  },
  UPDATE_INCIDENT_WITH_RESOURCE(state, updatedIncident) {
    state.selected = updatedIncident;
  },
  UPDATE_STEP(state, payload) {
    const stepIndex = state.selected.steps.findIndex(step => step.id === payload.stepId);
    if (stepIndex !== -1) {
      Vue.set(state.selected.steps, stepIndex, {
        ...state.selected.steps[stepIndex],
        ...payload.step
      });
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
