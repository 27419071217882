/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/

import API from "@/api"

const resource = "incident_steps"

export default {
    getIncidentSteps(incidentId) {
        return API.get(`/${resource}/${incidentId}/steps`)
    },

    updateIncidentStep(incidentId, stepId, payload) {
        return API.put(`/${resource}/${incidentId}/${stepId}`, payload)
    },
    getStepAiSummary(incidentId, stepId) {
        return API.get(`/${resource}/${incidentId}/${stepId}/ai-summary`)
    },
}
