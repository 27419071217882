/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import jwt_decode from "jwt-decode"
import router from "@/router/index"
import { differenceInMilliseconds, fromUnixTime, subMinutes } from "date-fns"
import { getField, updateField } from "vuex-map-fields"
import { debounce, set } from "lodash"
import UserApi from "./api"
import store from "@/store"

const getDefaultSelectedState = () => {
  return {
    id: null,
    email: null,
    loading: false,
    projects: null,
    role: null,
    organization_slug: null,
  }
}

const state = {
  currentUser: {
    loggedIn: false,
    token: null,
    email: "",
    id: null,
    projects: [],
    role: null,
  },
  currentOrganization: localStorage.getItem("organization") || "default",
  currentProject: localStorage.getItem("project") || null,
  selected: {
    ...getDefaultSelectedState(),
  },
  loading: false,
  oauthLoading: false,
  dialogs: {
    showCreateEdit: false,
  },
  table: {
    rows: {
      items: [],
      total: null,
    },
    options: {
      q: "",
      page: 1,
      itemsPerPage: 10,
      sortBy: ["email"],
      descending: [true],
    },
    loading: false,
  },
}

const actions = {
  getAll: debounce(({ commit, state }) => {
    commit("SET_TABLE_LOADING", "primary")
    return UserApi.getAll(state.table.options).then((response) => {
      commit("SET_TABLE_LOADING", false)
      commit("SET_TABLE_ROWS", response.data)
    })
  }, 500),
  createEditShow({ commit }, plugin) {
    commit("SET_DIALOG_CREATE_EDIT", true)
    if (plugin) {
      commit("SET_SELECTED", plugin)
    }
  },
  closeCreateEdit({ commit }) {
    commit("SET_DIALOG_CREATE_EDIT", false)
    commit("RESET_SELECTED")
  },
  save({ commit, dispatch }) {
    commit("SET_SELECTED_LOADING", true)
    if (!state.selected.id) {
      return UserApi.create(state.selected).then(() => {
        dispatch("closeCreateEdit")
        dispatch("getAll")
        commit("SET_SELECTED_LOADING", false)
        commit(
          "notification_backend/addBeNotification",
          { text: "User created successfully.", type: "success" },
          { root: true }
        )
      })
    } else {
      return UserApi.update(state.selected.id, state.selected).then(() => {
        commit("SET_USER_PROJECTS", state.selected.projects)
        dispatch("closeCreateEdit")
        dispatch("getAll")
        commit("SET_SELECTED_LOADING", false)
        commit(
          "notification_backend/addBeNotification",
          { text: "User updated successfully.", type: "success" },
          { root: true }
        )
      })
    }
  },
  remove({ commit, dispatch }) {
    return UserApi.delete(state.selected.id).then(function () {
      dispatch("closeRemove")
      dispatch("getAll")
      commit(
        "notification_backend/addBeNotification",
        { text: "User deleted successfully.", type: "success" },
        { root: true }
      )
    })
  },
  loginRedirect({ state }, redirectUri) {
    let redirectUrl = new URL(redirectUri)
    void state
    let queryMap = {}
    for (var pair of redirectUrl.searchParams.entries()) {
      if (pair[0] in queryMap) {
        queryMap[pair[0]].push(pair[1])
      } else {
        queryMap[pair[0]] = [pair[1]]
      }
    }
    router.push({ path: redirectUrl.pathname, query: queryMap })
  },
  async sendVerificationCode({ commit }, email) {
    commit("SET_BASIC_LOGIN_LOADING", true);
    try {
      const response = await UserApi.sendVerificationCode(email);
      if (response.data.redirect) {
        commit(
          "notification_backend/addBeNotification",
          { text: "Redirecting to your base cluster...", type: "info" },
          { root: true }
        );
        setTimeout(() => {
          window.location.href = response.data.redirect;
        }, 2000);
      } else {
        commit(
          "notification_backend/addBeNotification",
          { text: "Verification code sent to your email.", type: "success" },
          { root: true }
        );
      }
      return response.data;
    } catch (error) {
      console.error("Send verification code error:", error);
      commit(
        "notification_backend/addBeNotification",
        { text: "Failed to send verification code. Please try again.", type: "error" },
        { root: true }
      );
      throw error;
    } finally {
      commit("SET_BASIC_LOGIN_LOADING", false);
    }
  },

  async verifyCodeAndLogin({ commit }, { email, code }) {
    commit("SET_BASIC_LOGIN_LOADING", true);
    try {
      const response = await UserApi.verifyCode(email, code);
      commit("SET_USER_LOGIN", response.data.token);
      commit("SET_USER_PROJECTS", response.data.projects);

      const storedOrg = localStorage.getItem("organization");
      if (storedOrg) {
        commit("SET_CURRENT_ORGANIZATION", storedOrg);
      } else {
        commit("SET_CURRENT_ORGANIZATION", response.data.organization.slug);
        localStorage.setItem("organization", response.data.organization.slug);
      }

      router.push({
        name: "chat",
        params: { organization: storedOrg || response.data.organization.slug }
      });
    } catch (error) {
      console.error("Verification error:", error);
      commit(
        "notification_backend/addBeNotification",
        { text: "Invalid or expired verification code. Please try again.", type: "error" },
        { root: true }
      );
      throw error;
    } finally {
      commit("SET_BASIC_LOGIN_LOADING", false);
    }
  },
  oauthLogin({ commit }) {
    commit("SET_OAUTH_LOGIN_LOADING", true);
    UserApi.oauthLogin()
  },
  postOauthLogin({ commit }, token) {
    commit("SET_BASIC_LOGIN_LOADING", true);
    UserApi.postOauthLogin(token)
      .then(function (response) {
        commit("SET_USER_LOGIN", response.data.token);
        commit("SET_USER_PROJECTS", response.data.projects);

        const storedOrg = localStorage.getItem("organization");
        if (storedOrg) {
          commit("SET_CURRENT_ORGANIZATION", storedOrg);
        } else {
          commit("SET_CURRENT_ORGANIZATION", response.data.organization.slug);
          localStorage.setItem("organization", response.data.organization.slug);
        }

        // Redirect to the chat route with the appropriate organization
        router.push({
          name: "chat",
          params: { organization: storedOrg || response.data.organization.slug }
        });
      })
      .catch(function (error) {
        commit(
          "notification_backend/addBeNotification",
          { text: error.response.data.message, type: "error" },
          { root: true }
        );
      })
      .finally(() => {
        commit("SET_BASIC_LOGIN_LOADING", false);
      });
  },
  login({ dispatch, commit }, payload) {
    commit("SET_USER_LOGIN", payload)
    dispatch("loginRedirect", payload.redirectUri).then(() => {
      dispatch("createExpirationCheck")
    })
  },
  logout({ commit }) {
    localStorage.removeItem("token")
    commit("SET_USER_LOGOUT")
    router.go()
  },
  createExpirationCheck({ state, commit }) {
    // expiration time minus 10 min
    let expire_at = subMinutes(fromUnixTime(state.currentUser.exp), 10)
    let now = new Date()

    setTimeout(function () {
      commit(
        "app/SET_REFRESH",
        { show: true, message: "Your credentials have expired. Please refresh the page." },
        { root: true }
      )
    }, differenceInMilliseconds(expire_at, now))
  },
}

const mutations = {
  updateField,
  SET_SELECTED(state, value) {
    state.selected = Object.assign(state.selected, value)
  },
  SET_SELECTED_LOADING(state, value) {
    state.selected.loading = value
  },
  SET_TABLE_LOADING(state, value) {
    state.table.loading = value
  },
  SET_TABLE_ROWS(state, value) {
    state.table.rows = value
  },
  SET_DIALOG_CREATE_EDIT(state, value) {
    state.dialogs.showCreateEdit = value
  },
  RESET_SELECTED(state) {
    state.selected = Object.assign(state.selected, getDefaultSelectedState())
  },
  SET_BASIC_LOGIN_LOADING(state, value) {
    state.loading = value
  },
  SET_OAUTH_LOGIN_LOADING(state, value) {
    state.oauthLoading = value
  },
  SET_USER_LOGIN(state, token) {
    state.currentUser = {
      ...state.currentUser,
      ...jwt_decode(token),
      token: token,
      loggedIn: true,
    }
    localStorage.setItem("token", token)
  },
  SET_USER_LOGOUT(state) {
    state.currentUser = { loggedIn: false }
  },
  SET_USER_PROJECTS(state, value) {
    state.currentUser.projects = value
  },
  SET_CURRENT_PROJECT(state, projectId) {
    state.currentProject = projectId
    localStorage.setItem("project", projectId)
  },
  SET_CURRENT_ORGANIZATION(state, organizationSlug) {
    state.currentOrganization = organizationSlug
    localStorage.setItem("organization", organizationSlug)
    store.state.route.params.organization = organizationSlug
  },
}

const getters = {
  getField,
  userAvatarURL: (state) => {
    if (state.currentUser.userId) {
      return `${window.location.protocol}//${window.location.host}/avatar/${state.currentUser.userId}/${state.currentUser.userId}.json`
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
