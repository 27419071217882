/*
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
export var opts = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#004bfb",
        secondary: "#34495e",
        accent: "#8c9eff",
        anchor: "#0F0F0F",
        error: "#F43F5E",
        info: "#004bfb",
        tips: "#FF1493",
        warning: "#FFA000",
        success: "#10B981",
        toolbar: "#FFFFFF",
        background: "#FAFAFA",
        background0: "#FFFFFF",
        background1: "#FAFAFA",
        background2: "#F0F0F0",
        gray0: "#0F0F0F",
        gray1: "#161616",
        gray2: "#232323",
        gray3: "#404041",
        gray4: "#6D6D6E",
        gray5: "#929292",
        gray6: "#B6B6B7",
        gray7: "#DEDEDE",
        gray8: "#F0F0F0",
        gray9: "#FAFAFA",
        white: "#FFFFFF",
        borderline: "#E4E4E4",
      },
      dark: {
        primary: "#004bfb",
        secondary: "#34495e",
        accent: "#8c9eff",
        error: "#F43F5E",
        info: "#004bfb",
        tips: "#FF1493",
        warning: "#FFA000",
        success: "#10B981",
        toolbar: "#404041",
        background: "#0F0F0F",
        background0: "#0F0F0F",
        background1: "#161616",
        background2: "#232323",
        gray0: "#0F0F0F",
        gray1: "#161616",
        gray2: "#232323",
        gray3: "#404041",
        gray4: "#6D6D6E",
        gray5: "#929292",
        gray6: "#B6B6B7",
        gray7: "#DEDEDE",
        gray8: "#F0F0F0",
        gray9: "#FAFAFA",
        white: "#FFFFFF",
        borderline: "#404040",
      },
    },
    options: {
      customProperties: true,
    },
  },
}
