<template>
  <div class="loading-overlay">
    <img src="/static/unstruct-logo-new.png" alt="Loading..." class="loading-image"/>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  name: 'Loading',
  computed: {
    ...mapFields("app", ["loading"]),
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-image {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* This makes the image circular */
  object-fit: cover; /* This ensures the image fills the circular space */
  box-shadow: 0 0 0 5px #004bfb; /* Outer ring color and size */
  animation: rotate 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
