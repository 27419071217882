import API from "@/api"

const resource = "/entity_type"

export default {
  getAll(options) {
    return API.get(`${resource}`, { params: { ...options } })
  },

  get(entityTypeId) {
    return API.get(`${resource}/${entityTypeId}`)
  },

  create(payload) {
    return API.post(`${resource}`, payload)
  },

  update(entityTypeId, payload) {
    return API.put(`${resource}/${entityTypeId}`, payload)
  },

  process(entityTypeId, payload) {
    return API.put(`${resource}/${entityTypeId}/process`, payload)
  },

  delete(entityTypeId) {
    return API.delete(`${resource}/${entityTypeId}`)
  },

  async getCases(entityTypeId, daysBack) {
    return await API.get(`${resource}/${entityTypeId}/cases/${daysBack}`)
  },

  async getIncidents(entityTypeId, daysBack) {
    return await API.get(`${resource}/${entityTypeId}/incidents/${daysBack}`)
  },

  async getSignalInstances(entityTypeId, daysBack) {
    return await API.get(`${resource}/${entityTypeId}/signal_instances/${daysBack}`)
  },

  async getCommits(entityTypeId, daysBack) {
    return await API.get(`${resource}/${entityTypeId}/commits/${daysBack}`)
  },
  async getTickets(entityTypeId, daysBack) {
    return await API.get(`${resource}/${entityTypeId}/tickets/${daysBack}`)
  }
}
