// store/modules/submissions.js
import fileUploadAPI from '@/user_submissions/api';

export default {
    namespaced: true,
    state: {
        textSubmissions: [],
        fileSubmissions: [],
        youtubeTranscriptSubmissions: [],
        eccsProjects: [],
        loading: false,
        error: null
    },
    mutations: {
        SET_TEXT_SUBMISSIONS(state, submissions) {
            state.textSubmissions = submissions;
        },
        SET_FILE_SUBMISSIONS(state, submissions) {
            state.fileSubmissions = submissions;
        },
        SET_YOUTUBE_TRANSCRIPT_SUBMISSIONS(state, submissions) {
            state.youtubeTranscriptSubmissions = submissions;
        },
        SET_ECCS_PROJECTS(state, eccsProjects) {
            state.eccsProjects = eccsProjects;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        ADD_FILE_SUBMISSION(state, submission) {
            state.fileSubmissions.push(submission);
        },
    },
    actions: {
        fetchSubmissions({ commit, rootState }, projectId) {
            commit('SET_LOADING', true);
            if (rootState.socket && rootState.socket.readyState === WebSocket.OPEN) {
                rootState.socket.send(JSON.stringify({ type: 'fetch_submissions', project_id: projectId }));
            } else {
                commit('SET_ERROR', 'WebSocket is not connected');
                commit('SET_LOADING', false);
            }
        },
        receiveSubmissions({ commit }, { textSubmissions, fileSubmissions, youtubeTranscriptSubmissions, eccsProjects }) {
            commit('SET_TEXT_SUBMISSIONS', textSubmissions);
            commit('SET_FILE_SUBMISSIONS', fileSubmissions);
            commit('SET_YOUTUBE_TRANSCRIPT_SUBMISSIONS', youtubeTranscriptSubmissions);
            commit('SET_ECCS_PROJECTS', eccsProjects);
            commit('SET_LOADING', false);
        },

        async uploadFile({ commit, rootState }, { file, projectId, eccsProjectId, buildIndex, sensitivityLevel }) {
            commit('SET_LOADING', true);
            commit('SET_ERROR', null);

            try {
                if (!rootState.socket) {
                    throw new Error("WebSocket connection not established");
                }

                const response = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = () => {
                        const base64File = reader.result.split(',')[1];

                        // Prepare WebSocket upload message
                        const data = {
                            type: "file_upload",
                            fileName: file.name,
                            fileType: file.type,
                            fileContent: base64File,
                            buildIndex,
                            project_id: projectId,
                            eccs_project_id: eccsProjectId,
                            sensitivityLevel
                        };

                        // Send file data through WebSocket
                        rootState.socket.send(JSON.stringify(data));

                        // Handle WebSocket response
                        const handleResponse = (event) => {
                            const wsResponse = JSON.parse(event.data);
                            if (wsResponse.type === "submit_file_response") {
                                rootState.socket.removeEventListener('message', handleResponse);

                                if (wsResponse.status === 'success') {
                                    resolve({
                                        status: 'success',
                                        submission_id: wsResponse.submission_id,
                                        message: wsResponse.message
                                    });
                                } else {
                                    reject(new Error(wsResponse.message || 'Upload failed'));
                                }
                            }
                        };

                        rootState.socket.addEventListener('message', handleResponse);
                    };

                    reader.onerror = (error) => {
                        reject(new Error('Failed to read file'));
                    };
                });

                // Handle successful upload
                commit('ADD_FILE_SUBMISSION', {
                    id: response.submission_id,
                    fileName: file.name,
                    status: 'uploaded',
                });

                return response;

            } catch (error) {
                commit('SET_ERROR', error.message);
                throw error;
            } finally {
                commit('SET_LOADING', false);
            }
        },
    },
    getters: {
        isLoading: state => state.loading,
        getError: state => state.error,
        getFileSubmissions: state => state.fileSubmissions,
        getSubmissionsByEccsProject: (state) => (eccsProjectId) => {
            return {
                textSubmissions: state.textSubmissions.filter(s => s.eccs_project_id === eccsProjectId),
                fileSubmissions: state.fileSubmissions.filter(s => s.eccs_project_id === eccsProjectId),
                youtubeTranscriptSubmissions: state.youtubeTranscriptSubmissions.filter(s => s.eccs_project_id === eccsProjectId)
            }
        },
        hasError: state => state.error !== null,
        getAllSubmissions: state => ({
            textSubmissions: state.textSubmissions,
            fileSubmissions: state.fileSubmissions,
            youtubeTranscriptSubmissions: state.youtubeTranscriptSubmissions
        })
    }
};
