/*
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
*/
import { getField, updateField } from "vuex-map-fields"
import { debounce } from "lodash"

import SearchUtils from "@/search/utils"
import OncallHandoffApi from "@/oncall/handoff/api"
import router from "@/router"

const getDefaultSelectedState = () => {
    return {
        id: null,
        handover_from: null,
        handover_to: null,
        start_time: null,
        end_time: null,
        shift_type: null,
        location_type: null,
        location_details: null,
        critical_ongoing_issues: null,
        tasks_completed: null,
        pending_tasks: null,
        mentions: null,
        tools_and_access: null,
        feedback: null,
        handoff_completed: null,
        loading: false,
        project: null,
    }
}

const state = {
    selected: {
        ...getDefaultSelectedState(),
    },
    dialogs: {
        showCreateEdit: false,
        showRemove: false,
        showNewSheet: false,
    },
    table: {
        rows: {
            items: [],
            total: null,
        },
        options: {
            q: "",
            page: 1,
            itemsPerPage: 25,
            sortBy: ["end_time"],
            descending: [true],
            filters: {
                project: [],
                individual_contact: [],
            },
        },
        loading: false,
    },
}

const getters = {
    getField,
}

const actions = {
    getAll: debounce(({ commit, state }) => {
        commit("SET_TABLE_LOADING", true)
        let params = SearchUtils.createParametersFromTableOptions({ ...state.table.options }, "OncallHandoff")
        return OncallHandoffApi.getAll(params)
            .then((response) => {
                commit("SET_TABLE_LOADING", false)
                commit("SET_TABLE_ROWS", response.data)
            })
            .catch(() => {
                commit("SET_TABLE_LOADING", false)
            })
    }, 500),
    getDetails({ commit, state }, payload) {
        commit("SET_SELECTED_LOADING", true)
        if ("id" in payload && payload.id !== undefined) {
            return OncallHandoffApi.get(payload.id)
                .then((response) => {
                    commit("SET_SELECTED", response.data)
                    commit("SET_SELECTED_LOADING", false)
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        }
    },
    createEditShow({ commit }, oncallhandoff) {
        commit("SET_DIALOG_CREATE_EDIT", true)
        if (oncallhandoff) {
            commit("SET_SELECTED", oncallhandoff)
        }
    },
    removeShow({ commit }, oncallhandoff) {
        commit("SET_DIALOG_DELETE", true)
        commit("SET_SELECTED", oncallhandoff)
    },
    closeCreateEdit({ commit }) {
        commit("SET_DIALOG_CREATE_EDIT", false)
        commit("RESET_SELECTED")
        commit("SET_DIALOG_SHOW_EDIT_SHEET", false)
        router.push({ name: "OncallHandoffTable" })
    },
    closeRemove({ commit }) {
        commit("SET_DIALOG_DELETE", false)
        commit("RESET_SELECTED")
    },
    save({ commit, dispatch }) {
        commit("SET_SELECTED_LOADING", true)
        if (!state.selected.id) {
            return OncallHandoffApi.create(state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeNewSheet")
                    dispatch("getAll")
                    dispatch("closeCreateEdit")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Oncall Handoff created successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        } else {
            commit("SET_SELECTED_LOADING", true)
            return OncallHandoffApi.update(state.selected.id, state.selected)
                .then(() => {
                    commit("SET_SELECTED_LOADING", false)
                    dispatch("closeCreateEdit")
                    dispatch("closeNewSheet")
                    dispatch("getAll")
                    commit(
                        "notification_backend/addBeNotification",
                        { text: "Oncall Handoff updated successfully.", type: "success" },
                        { root: true }
                    )
                })
                .catch(() => {
                    commit("SET_SELECTED_LOADING", false)
                })
        }
    },
    showNewSheet({ commit }, value) {
        commit("SET_DIALOG_SHOW_NEW_SHEET", true)
        commit("RESET_SELECTED")
        if (value) {
            commit("SET_SELECTED", value)
        }
    },
    closeNewSheet({ commit }) {
        commit("SET_DIALOG_SHOW_NEW_SHEET", false)
        commit("RESET_SELECTED")
    },
    remove({ commit, dispatch }) {
        return OncallHandoffApi.delete(state.selected.id).then(function () {
            dispatch("closeRemove")
            dispatch("getAll")
            commit(
                "notification_backend/addBeNotification",
                { text: "Oncall Handoff deleted successfully.", type: "success" },
                { root: true }
            )
        })
    },
}

const mutations = {
    updateField,
    SET_SELECTED(state, value) {
        state.selected = Object.assign(state.selected, value)
    },
    SET_SELECTED_LOADING(state, value) {
        state.selected.loading = value
    },
    SET_TABLE_LOADING(state, value) {
        state.table.loading = value
    },
    SET_TABLE_ROWS(state, value) {
        state.table.rows = value
    },
    SET_DIALOG_CREATE_EDIT(state, value) {
        state.dialogs.showCreateEdit = value
    },
    SET_DIALOG_SHOW_NEW_SHEET(state, value) {
        state.dialogs.showNewSheet = value
    },
    SET_DIALOG_DELETE(state, value) {
        state.dialogs.showRemove = value
    },
    SET_DIALOG_SHOW_EDIT_SHEET(state, value) {
        state.dialogs.showEditSheet = value
    },
    RESET_SELECTED(state) {
        state.selected = { ...getDefaultSelectedState() }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
