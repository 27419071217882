// store/modules/user_interactions.js

export default {
    namespaced: true,
    state: {
        likedMessagesProcessing: [],
        loading: false,
        error: null
    },
    mutations: {
        SET_LIKED_MESSAGES_PROCESSING(state, messages) {
            state.likedMessagesProcessing = [...messages];
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        }
    },
    actions: {
        fetchLikedMessagesProcessing({ commit, rootState }, projectId) {
            commit('SET_LOADING', true);
            if (rootState.socket && rootState.socket.readyState === WebSocket.OPEN) {
                rootState.socket.send(JSON.stringify({ type: 'fetch_liked_messages_processing', project_id: projectId }));
            } else {
                commit('SET_ERROR', 'WebSocket is not connected');
                commit('SET_LOADING', false);
            }
        },
        receiveLikedMessagesProcessing({ commit }, messageData) {
            commit('SET_LIKED_MESSAGES_PROCESSING', messageData.messages);
            commit('SET_LOADING', false);
        }
    },
    getters: {
        hasError: state => state.error !== null
    }
};
