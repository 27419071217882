<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-system-bar
    v-if="currentOrganization.banner_enabled"
    :color="currentOrganization.banner_enabled ? currentOrganization.banner_color : defaultColor"
    height="64px"
    app
  >
    <v-container class="fill-height px-0" fluid>
      <v-row align="center" justify="start" no-gutters>
        <v-col cols="auto">
          <v-icon x-large color="white">mdi-domain</v-icon>
        </v-col>
        <v-col cols="auto" class="hide-on-mobile">
          <span class="white--text bold-text">
            {{ currentOrganization.banner_text || currentOrganization.name  || 'Incident Copilot - OpsGPT Bot - AI Assistant'}}
            <template v-if="isTrialActive">
              - {{ trialTimeLeft }} left in your free trial.
            </template>
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="hide-on-mobile">
            <a id="slack-install-link" class="slack-install-link">
                <img alt="Add to Slack" height="40" width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
            </a>
        </v-col>
        <v-col cols="auto" class="ml-10 mr-5 hide-on-mobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" color="secondary" text-color="white" class="d-flex align-center">
                <v-icon left color="white">
                  mdi-send
                </v-icon>
                <strong>Contact UnStruct</strong>
              </v-chip>
            </template>
            <v-list>
              <v-list-item @click="openEmail">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Email</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openPhone">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Phone</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openSlack">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-slack</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Slack</v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDemo">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-desktop-mac</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Book a Demo</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>

      </v-row>
    </v-container>
  </v-system-bar>
</template>

<script>
import { mapFields } from "vuex-map-fields"

import OrganizationApi from "@/organization/api"
import SearchUtils from "@/search/utils"
import store from "@/store"

export default {
  data() {
    return {
      currentOrganization: {},
      currentFeatureIndex: 0,
      criticalFeatures: [
        { icon: 'mdi-slack', text: 'Activate Slack for real-time collaboration magic', path: 'path' },
        { icon: 'mdi-jira', text: 'Sync with Jira for seamless issue tracking', path: 'path' },
        { icon: 'mdi-video', text: 'Automate transcript collection with Zoom', path: 'path' },
        { icon: 'mdi-google', text: 'Integrate Gsuite for efficient workflow', path: 'path' },
        { icon: 'mdi-phone-in-talk', text: 'Streamline on-call processes with PagerDuty', path: 'path' },
        { icon: 'mdi-creation', text: 'Leverage AI for advanced incident management', path: 'path' },
      ],
      defaultColor: "#1565C0",
      baseHref: "https://slack.com/oauth/v2/authorize?",
      scope: "scope=app_mentions:read,bookmarks:write,channels:history,channels:manage,channels:read,chat:write,commands,emoji:read,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:read,mpim:write,pins:write,reactions:read,reactions:write,team:read,users.profile:read,users:read,users:read.email,users:write",
      userScope: "user_scope=channels:read,groups:history,groups:read",
    }
  },

  computed: {
    ...mapFields("route", ["params"]),
    isTrialActive() {
      return this.currentOrganization && this.currentOrganization.plan === 'free';
    },
    trialTimeLeft() {
      if (!this.isTrialActive || !this.currentOrganization.free_trial_end) {
        return '';
      }

      const endDate = new Date(this.currentOrganization.free_trial_end);
      const today = new Date();
      const timeDiff = endDate - today;

      if (timeDiff < 0) {
        return 'Trial expired';
      }

      const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return `${daysLeft} days`;
    },
    organizationSlug() {
      return window.location.pathname.split("/")[1];
    },
  },

  mounted () {
    this.slackInstallLink();
  },

  methods: {
    slackInstallLink() {
      const host = window.location.hostname;
      const linkElement = document.getElementById('slack-install-link');
      let clientId;

      // Adjust clientId based on the host
      if (host === "incident.unstructai.com") {
        clientId = "client_id=1058428797445.6339616666567"; // Staging
      } else if (host === "ecsp-stage.unstructai.com") {
        clientId = "client_id=1058428797445.6339616666567"; // Staging
      } else if (host === "ecsp-dev.unstructai.com") {
        clientId = "client_id=1058428797445.6339616666567"; // Dev/staging
      } else if (host === "ecsp.unstruct.ai") {
        clientId = "client_id=1058428797445.6680803679008"; // Production
      } else {
        clientId = "client_id=1058428797445.6680803679008"; // Default is production for scaling. E.g. incident1, incident2, etc.
      }

      if (linkElement) {
        linkElement.href = `${this.baseHref}${clientId}&${this.scope}&${this.userScope}`;
      }
    },
    settingsPath(path) {
      if (path === 'path') {
        // TODO: Update this to the correct path
        return `/${this.organizationSlug}/settings/projects/plugins?project=default`;
      }
      return `/${this.organizationSlug}/settings/projects/plugins?project=default`;
    },
    navigateToSettings(settingsPath) {
      const url = this.settingsPath(settingsPath);
      this.$router.push(url);
    },
    openPhone() {
      const phoneNumber = "650-430-6223";
      const phoneDialer = `tel:${phoneNumber}`;
      window.location.href = phoneDialer;
    },
    openSlack() {
      const slackChannel = "https://unstruct-inc.slack.com";
      window.open(slackChannel, "_blank");
    },
    openEmail() {
      const email = "support@unstruct.ai";
      const subject = "unstruct.ai Support";
      const body = "Hi unstruct.ai Team,\n\nI have a question about unstruct.ai Incident Copilot.\n\nThanks,\n";
      const mailto = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailto;
    },
    openDemo() {
      const demoLink = "https://calendly.com/unstructai";
      window.open(demoLink, "_blank");
    },
    fetchOrganizationData() {
      const filterOptions = SearchUtils.createParametersFromTableOptions({
        itemsPerPage: 50,
        sortBy: ["name"],
        descending: [false],
      });

      OrganizationApi.getAll(filterOptions)
        .then(response => {
          this.currentOrganization = response.data.items[0];
        })
        .catch(error => {
          console.log(error);
        });
    },
    waitForTokenToSet() {
      return new Promise(resolve => {
        const checkToken = () => {
          if (localStorage.getItem("token") && localStorage.getItem("organization")) {
            resolve();
          } else {
            setTimeout(checkToken, 100);
          }
        };
        checkToken();
      });
    },
  },

  created() {
    this.waitForTokenToSet()
      .then(() => {
        this.fetchOrganizationData();
      })
      .catch(error => {
        console.log(error);
      });
  },
}

</script>


<style scoped>

a {
  color: white !important;
  text-decoration: none;
}
.feature-count {
  color: white;
  font-weight: bold;
}

.fill-height {
  height: 130%;
}
.carousel-feature-text {
  color: white !important;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.bold-text {
  font-weight: bold;
}
.link-icons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 30px;
  padding-top: 15px;
}

.v-application a {
  color: white !important;
}
.link-icons a {
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: white !important;
  text-decoration: none;
  font-weight: bold;
  padding-left: 15px;
}

.link-icons a:hover {
  color: red;
}

@media (max-width: 600px) {
  .link-icons {
    display: none !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
}


</style>
