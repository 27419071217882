import Vue from "vue"
import Vuex from "vuex"
import { getField } from "vuex-map-fields"

import admin_settings from "@/admin_settings/store"
import app from "@/app/store"
import apiKeys from "@/apiKeys/store"
import auth from "@/auth/store"
import bestpractice from "@/data/bestpractice/store"
import case_management from "@/case/store"
import case_priority from "@/case/priority/store"
import case_severity from "@/case/severity/store"
import case_type from "@/case/type/store"
import capacity from "@/capacity/store"
import command from "@/data/command/store"
import conversation from "@/conversation/store"
import conference from "@/conference/store"
import commit from "@/commit/store"
import cost_model from "@/cost_model/store"
import definition from "@/definition/store"
import document from "@/document/store"
import entity from "@/entity/store"
import entity_type from "@/entity_type/store"
import event from "@/event/store"
import incident_feedback from "@/feedback/incident/store"
import service_feedback from "@/feedback/service/store"
import incident from "@/incident/store"
import incident_cost_type from "@/incident_cost_type/store"
import incident_priority from "@/incident/priority/store"
import incident_severity from "@/incident/severity/store"
import incident_type from "@/incident/type/store"
import oncall_handoff from "@/oncall/handoff/store"
import oncall_preshift_prep from "@/oncall/preshift_prep/store"
import individual from "@/individual/store"
import notification from "@/notification/store"
import notification_backend from "@/app/notificationStore"
import organization from "@/organization/store"
import okrs from "@/okrs/store"
import playground from "@/entity_type/playground/store"
import plugin from "@/plugin/store"
import project from "@/project/store"
import query from "@/data/query/store"
import reference from "@/document/reference/store"
import runbook from "@/document/runbook/store"
import search from "@/search/store"
import service from "@/service/store"
import signal from "@/signal/store"
import signalEngagement from "@/signal/engagement/store"
import signalFilter from "@/signal/filter/store"
import source from "@/data/source/store"
import sourceDataFormat from "@/data/source/dataFormat/store"
import sourceEnvironment from "@/data/source/environment/store"
import sourceStatus from "@/data/source/status/store"
import sourceTransport from "@/data/source/transport/store"
import sourceType from "@/data/source/type/store"
import tag from "@/tag/store"
import tag_type from "@/tag_type/store"
import task from "@/task/store"
import team from "@/team/store"
import ticket from "@/ticket/store"
import template from "@/document/template/store"
import term from "@/term/store"
import user_limits from "@/user_limits/store"
import user_submissions from "@/user_submissions/store"
import user_interactions from "@/user_interactions/store"
import weblink from "@/data/weblink/store"
import webcrawl from "@/webcrawl/store"
import workflow from "@/workflow/store"
import websocket from "@/websocket/store"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socket: null
  },
  mutations: {
    SET_SOCKET(state, socket) {
      state.socket = socket;
    }
  },
  modules: {
    admin_settings,
    app,
    apiKeys,
    auth,
    bestpractice,
    case_management,
    case_priority,
    case_severity,
    case_type,
    capacity,
    command,
    commit,
    cost_model,
    definition,
    document,
    entity,
    entity_type,
    event,
    incident_feedback,
    service_feedback,
    oncall_handoff,
    oncall_preshift_prep,
    incident,
    incident_cost_type,
    incident_priority,
    incident_severity,
    incident_type,
    individual,
    notification,
    notification_backend,
    organization,
    okrs,
    playground,
    plugin,
    project,
    query,
    reference,
    route: {
      namespaced: true,
      getters: {
        getField,
      },
    },
    runbook,
    search,
    service,
    source,
    sourceDataFormat,
    sourceEnvironment,
    sourceStatus,
    sourceTransport,
    sourceType,
    signal,
    signalEngagement,
    signalFilter,
    tag,
    tag_type,
    task,
    team,
    template,
    term,
    ticket,
    weblink,
    workflow,
    conversation,
    conference,
    user_limits,
    user_submissions,
    user_interactions,
    webcrawl,
    websocket,
  },
  strict: process.env.NODE_ENV !== "production",
})
