// store/modules/userLimits.js
export default {
    namespaced: true,
    state: {
        limits: null,
        loading: false,
        error: null
    },
    mutations: {
        SET_LIMITS(state, limits) {
            state.limits = limits;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_ERROR(state, error) {
            state.error = error;
        }
    },
    actions: {
        fetchLimits({ commit, rootState }) {
            commit('SET_LOADING', true);
            if (rootState.socket && rootState.socket.readyState === WebSocket.OPEN) {
                rootState.socket.send(JSON.stringify({ type: 'fetch_limits' }));
            } else {
                commit('SET_ERROR', 'WebSocket is not connected');
                commit('SET_LOADING', false);
            }
        },
        receiveLimits({ commit }, limits) {
            commit('SET_LIMITS', limits);
            commit('SET_LOADING', false);
        }
    },
    getters: {
        getUserLimits: state => state.limits,
        isLoading: state => state.loading,
        hasError: state => state.error !== null
    }
};
