// api/fileUpload.js
import API from "@/api"

const resource = '/file_uploads';

export default {
    uploadFile(formData) {
        return API.post(`${resource}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
};
